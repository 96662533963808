import { HttpClient } from '@angular/common/http';
import { Injectable, effect, signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { BaseService } from 'src/app/core/base/base.service';
import { AppMessageService } from 'src/app/core/services/app-message.service';
import { RestService } from 'src/app/core/services/rest.service';
import { TabCloseEvent } from 'src/app/layout/api/tabcloseevent';
import { AppConstants } from 'src/app/core/utils/app.const';
import { AppStorageService } from 'src/app/core/utils/app.storage.service';

export interface AppConfig {
  inputStyle: string;
  theme: string;
  ripple: boolean;
  scale: number;
}

interface LayoutState {
  staticMenuDesktopInactive: boolean;
  overlayMenuActive: boolean;
  profileSidebarVisible: boolean;
  configSidebarVisible: boolean;
  staticMenuMobileActive: boolean;
  menuHoverActive: boolean;
  filtersSidebarVisible: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LayoutService extends BaseService {
  _config: AppConfig = {
    ripple: true,
    inputStyle: 'outlined',
    theme: 'p360',
    scale: 14,
  };

  config = signal<AppConfig>(this._config);

  state: LayoutState = {
    staticMenuDesktopInactive: false,
    overlayMenuActive: false,
    profileSidebarVisible: false,
    configSidebarVisible: false,
    staticMenuMobileActive: false,
    menuHoverActive: false,
    filtersSidebarVisible: false,
  };

  tabs: MenuItem[] = [];

  private configUpdate = new Subject<AppConfig>();

  private overlayOpen = new Subject<any>();

  private tabOpen = new Subject<MenuItem>();

  private tabClose = new Subject<TabCloseEvent>();

  configUpdate$ = this.configUpdate.asObservable();

  overlayOpen$ = this.overlayOpen.asObservable();

  tabOpen$ = this.tabOpen.asObservable();

  tabClose$ = this.tabClose.asObservable();

  constructor(
    override rest: RestService,
    override messageService: AppMessageService,
    override storage: AppStorageService,
    override http: HttpClient,
    override store: Store<any>
  ) {
    super();
    effect(() => {
      const config = this.config();
      if (this.updateStyle(config)) {
        this.changeTheme();
      }
      this.changeScale(config.scale);
      this.onConfigUpdate();
    });
  }

  updateStyle(config: AppConfig) {
    return config.theme !== this._config.theme;
  }

  changeTheme() {
    const config = this.config();
    const themeLink = <HTMLLinkElement>document.getElementById('theme-link');
    const themeLinkHref = themeLink.getAttribute('href')!;
    const newHref = themeLinkHref
      .split('/')
      .map((el) => (el == this._config.theme ? (el = config.theme) : el))
      .join('/');

    this.replaceThemeLink(newHref);
  }

  replaceThemeLink(href: string) {
    const id = 'theme-link';
    let themeLink = <HTMLLinkElement>document.getElementById(id);
    const cloneLinkElement = <HTMLLinkElement>themeLink.cloneNode(true);

    cloneLinkElement.setAttribute('href', href);
    cloneLinkElement.setAttribute('id', id + '-clone');

    themeLink.parentNode!.insertBefore(cloneLinkElement, themeLink.nextSibling);
    cloneLinkElement.addEventListener('load', () => {
      themeLink.remove();
      cloneLinkElement.setAttribute('id', id);
    });
  }

  onMenuToggle() {
    if (this.isDesktop()) {
      this.state.staticMenuDesktopInactive =
        !this.state.staticMenuDesktopInactive;
    } else {
      this.state.staticMenuMobileActive = !this.state.staticMenuMobileActive;

      if (this.state.staticMenuMobileActive) {
        this.overlayOpen.next(null);
      }
    }
  }

  onOverlaySubmenuOpen() {
    this.overlayOpen.next(null);
  }

  showProfileSidebar() {
    this.state.profileSidebarVisible = true;
  }

  showConfigSidebar() {
    this.state.configSidebarVisible = true;
  }

  isDesktop() {
    return window.innerWidth > 991;
  }

  isMobile() {
    return !this.isDesktop();
  }

  onConfigUpdate() {
    this._config = { ...this.config() };
    this.configUpdate.next(this.config());
  }

  onTabOpen(value: MenuItem) {
    this.tabOpen.next(value);
  }

  openTab(value: MenuItem) {
    this.tabs = [...this.tabs, value];
  }

  onTabClose(value: MenuItem, index: number) {
    this.tabClose.next({ tab: value, index: index });
  }

  closeTab(index: number) {
    this.tabs.splice(index, 1);
    this.tabs = [...this.tabs];
  }

  changeScale(value: number) {
    document.documentElement.style.fontSize = `${value}px`;
  }

  showFiltersSidebar() {
    this.state.filtersSidebarVisible = true;
  }

  logout = (): Observable<any> => {
    return this.rest.logout(`${AppConstants.SECURITY_HOST}/logout`);
  };
}
