import { Component, OnInit } from '@angular/core';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { Network } from '@capacitor/network';
import { PushNotifications } from '@capacitor/push-notifications';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import config from 'devextreme/core/config';
import { loadMessages, locale } from 'devextreme/localization';
import * as enMessages from 'devextreme/localization/messages/en.json';
import * as esMessages from 'devextreme/localization/messages/es.json';
import { CookieService } from 'ngx-cookie-service';
import { PrimeNGConfig } from 'primeng/api';
import { finalize } from 'rxjs';
import { AppService } from './app.service';
import { FirebaseService } from './core/services/firebase.service';
import { AppStorage } from './core/utils/app.storage';
import { AppStorageService } from './core/utils/app.storage.service';
import { SecurityUser } from './models/security/security-user';
import { appStoreGlobalActions } from './store/global/store.global.action';
import { appStoreScreenActions } from './store/screen/store.screen.action';
import { appStoreSettingsActions } from './store/settings/store.settings.action';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isWeb = false;
  isSmall = false;

  sessionInStorage = '';
  agentDocument = '';

  constructor(
    protected primengConfig: PrimeNGConfig,
    protected storage: AppStorageService,
    protected translate: TranslateService,
    protected platform: Platform,
    protected store: Store<any>,
    protected service: AppService,
    protected cookie: CookieService,
    protected firebaseService: FirebaseService
  ) {}

  ngOnInit(): void {
    this.platform.ready().then(() => {
      if (!this.platform.is('mobileweb') && !this.platform.is('desktop')) {
        Keyboard.setResizeMode({ mode: KeyboardResize.None }).then();
      }

      this.firebaseService.listeners();
      this.firebaseService.permission();

      this.primengConfig.ripple = true;

      this.translate
        .get('GLOBAL_PRIMENG')
        .subscribe(this.setLocalizationForPrimeNG);

      this.translate
        .get('GLOBAL_DEVEXTREME')
        .subscribe(this.setLocalizationForDevextreme);

      this.storage.get(AppStorage.SESSION).then((session) => {
        if (session) {
          this.sessionInStorage = session;
        }
      });

      this.storage.get(AppStorage.SETTINGS_LANGUAGE).then((languageStorage) => {
        const language = languageStorage ?? 'es';
        this.translate.setDefaultLang(language);
        this.translate.use(language);
        this.translate.defaultLang = language;

        this.store.dispatch(
          appStoreSettingsActions.setLanguageAction({
            language,
          })
        );
      });

      this.storage.get(AppStorage.SETTINGS_FORMAT).then((format) => {
        this.store.dispatch(
          appStoreSettingsActions.setFormatAction({
            format: format ?? 'units',
          })
        );
      });

      this.storage.get(AppStorage.SETTINGS_PRECISION).then((precision) => {
        this.store.dispatch(
          appStoreSettingsActions.setPrecisionAction({
            precision: precision ?? 0,
          })
        );
      });

      Network.getStatus().then((status) => {
        const connected = status.connected;
        this.store.dispatch(
          appStoreGlobalActions.setConnectedAction({
            connected,
          })
        );
      });

      this.calculateDeviceScreen();

      if (!this.isWeb) {
        if (this.isSmall) {
          ScreenOrientation.lock({ orientation: 'portrait' });
        } else {
          ScreenOrientation.unlock();
        }

        PushNotifications.requestPermissions().then((result) => {
          if (result.receive === 'granted') {
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register();
          } else {
            // Show some error
          }
        });
      }

      this.platform.resize.subscribe(() => {
        this.calculateDeviceScreen();
      });

      this.storage.get(AppStorage.USER).then((user: SecurityUser) => {
        if (user) {
          this.firebaseService.subscribe(user);

          this.agentDocument = `${user.collaborator.agentCode}`;

          this.store.dispatch(appStoreGlobalActions.setUserAction({ user }));

          this.store.dispatch(
            appStoreGlobalActions.setCollaboratorAction({
              collaborator: user.collaborator,
            })
          );

          this.store.dispatch(
            appStoreGlobalActions.setCompanyAction({ company: user.company })
          );

          this.store.dispatch(
            appStoreScreenActions.setClaimsAction({
              claims: user.claims,
            })
          );
        }

        setTimeout(() => {
          document
            .querySelector('.splash-screen-wrapper')
            .classList.add('hidden');
        }, 500);

        if (this.sessionInStorage) {
          this.handleAppData(user);
        }

        this.storage
          .get(AppStorage.FORMS.APPOINTMENTS)
          .then((appointments: any[]) => {
            this.store.dispatch(
              appStoreGlobalActions.setAppointmentsToSyncAction({
                appointmentsToSync: appointments?.length ?? 0,
              })
            );
          });

        this.storage.get(AppStorage.FORMS.ORDERS).then((orders: any[]) => {
          this.store.dispatch(
            appStoreGlobalActions.setOrdersToSyncAction({
              ordersToSync: orders?.length ?? 0,
            })
          );
        });

        this.storage
          .get(AppStorage.FORMS.CUSTOMERS)
          .then((customers: any[]) => {
            this.store.dispatch(
              appStoreGlobalActions.setCustomersToSyncAction({
                customersToSync: customers?.length ?? 0,
              })
            );
          });
      });

      Network.addListener('networkStatusChange', (status) => {
        const connected = status.connected;
        this.store.dispatch(
          appStoreGlobalActions.setConnectedAction({ connected })
        );
      });

      this.service?.cleanRestCache();
    });
  }

  private calculateDeviceScreen = () => {
    this.isWeb =
      this.platform.is('desktop') ?? this.platform.is('mobileweb') ?? false;
    this.isSmall =
      (this.platform.isPortrait() && this.platform.width() < 768) ||
      (this.platform.isLandscape() && this.platform.width() < 992);
    const isPortrait = this.platform.isPortrait();
    const isMedium = this.platform.width() < 992;
    const isLarge = this.platform.width() >= 992;

    this.store.dispatch(
      appStoreGlobalActions.setScreenSizeAction({
        isWeb: this.isWeb,
        isSmall: this.isSmall,
        isPortrait,
        isMedium,
        isLarge,
      })
    );

    this.store.dispatch(
      appStoreGlobalActions.setInnerHeightAction({
        innerHeight: 0,
      })
    );
    setTimeout(() => {
      const innerHelper: HTMLElement = document.querySelector(
        '.layout-content-inner'
      );
      const areaHelper: HTMLElement =
        document.querySelector('.main-area__helper');
      const footerHelper: HTMLElement =
        document.querySelector('.layout-footer');
      if (areaHelper && footerHelper) {
        const innerWidth = areaHelper?.clientWidth ?? 0;
        const innerHeight =
          (innerHelper?.scrollHeight ?? this.platform.height()) -
          (areaHelper?.offsetTop ?? 0) -
          (footerHelper?.scrollHeight ?? 0);

        this.store.dispatch(
          appStoreGlobalActions.setInnerHeightAction({
            innerHeight,
          })
        );

        this.store.dispatch(
          appStoreGlobalActions.setInnerWidthAction({
            innerWidth,
          })
        );
      }
    }, 750);
  };

  private setLocalizationForPrimeNG = (translation: any) => {
    this.primengConfig.translation = translation;
  };

  private setLocalizationForDevextreme = (translation: any) => {
    const language =
      this.translate.currentLang ??
      localStorage.getItem('p360Language') ??
      'es';
    const messages: any = {
      ...esMessages,
      ...enMessages,
    };
    messages[language] = {
      ...messages[language],
      ...translation['GLOBAL_DEVEXTREME'],
    };
    messages.default = { ...messages[language] };

    config({
      defaultCurrency: 'USD',
    });
    locale(`${language}-US`);
    loadMessages(messages);
  };

  private handleAppData = (user: SecurityUser) => {
    this.whoAmI();
    this.service.loadOfflineData(`${user.collaborator?.agentCode}`);
  };

  private whoAmI = () => {
    this.service
      .whoAmI()
      .pipe(
        finalize(() => {
          document
            .querySelector('.splash-screen-wrapper')
            .classList.add('hidden');
        })
      )
      .subscribe({
        error: () => {
          this.service.loadOfflineData(this.agentDocument);
        },
      });
  };

  // private startLoggingPosition = () => {
  //   if (!this.isWeb) {
  //     console.log('[DEV] Start logging position: ', new Date());
  //     interval(60000 * this.intervalToLog).subscribe(() => {
  //       Geolocation.getCurrentPosition({ timeout: 60000 })
  //         .then((position) => {
  //           this.currentPosition.latitude = position.coords.latitude;
  //           this.currentPosition.longitude = position.coords.longitude;
  //           this.storage
  //             .get(PharmaConstants.STORAGE_LABEL.AGENT)
  //             .then(this.saveRoute);
  //         })
  //         .catch((error) => {
  //           console.error('Error on getting current position. ', error);
  //           this.diagnostic.isLocationEnabled().then((isAvailable) => {
  //             if (!isAvailable) {
  //               this.isGlobalGpsDisabled = true;
  //             }
  //           });
  //         });
  //     });
  //   }
  // };

  // private saveRoute = (agent) => {
  //   if (
  //     agent != null &&
  //     this.currentPosition.latitude != null &&
  //     this.currentPosition.longitude != null
  //   ) {
  //     this.diagnostic
  //       .isLocationEnabled()
  //       .then((isAvailable) => (this.isGlobalGpsDisabled = !isAvailable));
  //     this.geocoder
  //       .reverseGeocode(
  //         this.currentPosition.latitude,
  //         this.currentPosition.longitude,
  //         this.defaultOptions
  //       )
  //       .then((result) =>
  //         this.pharmaService
  //           .saveRoute({
  //             agentCode: agent.user.agentCode,
  //             latitude: this.currentPosition.latitude,
  //             longitude: this.currentPosition.longitude,
  //             address:
  //               `${result[0].thoroughfare} - ${result[0].subLocality}. ${result[0].locality}, ` +
  //               `${result[0].countryName} (${result[0].postalCode})`,
  //           })
  //           .subscribe(
  //             (data) => {},
  //             (error) => console.error(`Couldn't log position.`)
  //           )
  //       );
  //   }
  // };
}
