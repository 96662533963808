<p-dialog
  #reusableDialog
  [(visible)]="showDialog"
  [dismissableMask]="dismissableMask"
  [closable]="closable"
  [position]="position"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  appendTo="body"
  [style]="{ maxWidth: maxWidth, width: '90vw', maxHeight: '90vh' }"
  (onHide)="handleCloseDialog()"
>
  <ng-template pTemplate="header">
    <h2 app-headline class="text-primary font-bold">
      {{ header ?? "" }}
    </h2>
  </ng-template>

  <ng-content></ng-content>
</p-dialog>
