import { Injectable } from '@angular/core';
import { FCM } from '@capacitor-community/fcm';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { PushNotifications } from '@capacitor/push-notifications';
import { Platform } from '@ionic/angular';
import { AppService } from 'src/app/app.service';
import { SecurityUser } from 'src/app/models/security/security-user';
import { AppStorageService } from '../utils/app.storage.service';
import { AppStorage } from '../utils/app.storage';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  isWeb = false;

  constructor(
    private platform: Platform,
    protected appService: AppService,
    protected storage: AppStorageService
  ) {
    this.isWeb = this.platform.is('desktop') || this.platform.is('mobileweb');
  }

  listeners = async () => {
    if (this.isWeb) {
      return 'No Listeners [WEB]';
    }

    // await PushNotifications.addListener('registration', (token) => {
    //   console.info('Registration token: ', token.value);
    // });

    // await PushNotifications.addListener('registrationError', (err) => {
    //   console.error('Registration error: ', err.error);
    // });

    await PushNotifications.addListener(
      'pushNotificationReceived',
      (notification) => {
        console.log('Push notification received: ', notification);
        if (
          notification?.data['shouldRefreshData'] &&
          notification?.data['agentCode']
        ) {
          const shouldRefreshData =
            `${notification?.data['shouldRefreshData']}`.toLowerCase();
          const agentCode = notification?.data['agentCode'];
          if (shouldRefreshData === 'true') {
            this.handleRefreshInfo(agentCode);
          }
        }
      }
    );

    await PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (actionPerformed) => {
        console.log('Push notification action performed', actionPerformed);
        if (
          actionPerformed.notification?.data['shouldRefreshData'] &&
          actionPerformed.notification?.data['agentCode']
        ) {
          const shouldRefreshData =
            `${actionPerformed.notification?.data['shouldRefreshData']}`.toLowerCase();
          const agentCode = actionPerformed.notification?.data['agentCode'];
          if (shouldRefreshData === 'true') {
            this.handleRefreshInfo(agentCode);
          }
        }
      }
    );

    return 'Set Listeners';
  };

  register = async () => {
    if (this.isWeb) {
      return 'Not registered [WEB]';
    }

    await PushNotifications.register();
    return 'registered';
  };

  permission = async () => {
    if (this.isWeb) {
      return 'Not needed [WEB]';
    }

    let permStatus = await PushNotifications.checkPermissions();
    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }
    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }
    return await this.register();
  };

  saveToken = async () => {};

  getToken = async () => {
    if (this.isWeb) {
      return 'web-token';
    }

    const token = await FCM.getToken();
    return token.token;
  };

  subscribe = (user?: SecurityUser) => {
    if (this.isWeb || !user) {
      return;
    }

    FCM.subscribeTo({
      topic: `Role--${user.collaborator.roleCode}--${user.company.id}`,
    });
    FCM.subscribeTo({
      topic: `Collaborator--${user.collaborator.agentCode}--${user.company.id}`,
    });
    FCM.subscribeTo({
      topic: `Supervisor--${user.collaborator.supervisorCode}--${user.company.id}`,
    });
    FCM.subscribeTo({ topic: `Company--${user.company.id}` });
    console.warn('Firebase FCM Subscribed');
  };

  unsubscribe = (user?: SecurityUser) => {
    if (this.isWeb || !user) {
      return;
    }

    FCM.unsubscribeFrom({
      topic: `Role--${user.collaborator.roleCode}--${user.company.id}`,
    });
    FCM.unsubscribeFrom({
      topic: `Collaborator--${user.collaborator.agentCode}--${user.company.id}`,
    });
    FCM.unsubscribeFrom({
      topic: `Supervisor--${user.collaborator.supervisorCode}--${user.company.id}`,
    });
    FCM.unsubscribeFrom({ topic: `Company--${user.company.id}` });
    console.warn('Firebase FCM Unsubscribed');
  };

  setScreenName = ({
    screenName,
    nameOverride,
  }: {
    screenName: string;
    nameOverride?: string;
  }): Promise<void> => {
    if (this.isWeb) {
      console.warn(
        '[DEV] Trigger Firebase Analytics from Web. SetScreenName: ',
        {
          screenName,
          nameOverride,
        }
      );
      return new Promise((resolve) => {
        resolve();
      });
    }

    return FirebaseAnalytics.setScreenName({ screenName, nameOverride });
  };

  setUserProperty = ({
    name,
    value,
  }: {
    name: string;
    value: string;
  }): Promise<void> => {
    if (this.isWeb) {
      console.warn(
        '[DEV] Trigger Firebase Analytics from Web. setUserProperty: ',
        {
          name,
          value,
        }
      );
      return new Promise((resolve) => {
        resolve();
      });
    }

    return FirebaseAnalytics.setUserProperty({ name, value });
  };

  logEvent = ({
    name,
    params,
  }: {
    name: string;
    params: object;
  }): Promise<void> => {
    if (this.isWeb) {
      console.warn('[DEV] Trigger Firebase Analytics from Web. logEvent: ', {
        name,
        params,
      });
      return new Promise((resolve) => {
        resolve();
      });
    }

    return FirebaseAnalytics.logEvent({ name, params });
  };

  private handleRefreshInfo = (agentCode: string) => {
    console.warn('¡Refresh information from push notification!');
    this.appService.handleRefreshInformation(agentCode, true);
  };
}
