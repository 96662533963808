import { AppStorageLabel } from 'src/app/models/app.storage';

export const AppStorage: AppStorageLabel = {
  SETTINGS: 'P360.Settings',
  GLOBAL_CATALOGS: 'P360.GlobalCatalogs',
  PRODUCT_ITEMS: 'P360.ProductItems',
  CLIENTS: 'P360.Clients',
  CLIENTS_FOR_FILTERS: 'P360.ClientsForFilters',
  COLLABORATORS: 'P360.Collaborators',
  LINES: 'P360.Lines',
  SEGMENTS: 'P360.Segments',
  DISPATCHERS: 'P360.Dispatchers',
  ITEMS: 'P360.Items',
  FORMS: {
    ORDERS: 'P360.Forms.Orders',
    APPOINTMENTS: 'P360.Forms.Appointments',
    STAKEHOLDERS: 'P360.Forms.Stakeholders',
    CUSTOMERS: 'P360.Forms.Customers',
  },

  SESSION: 'P360.Security.Session',
  USER: 'P360.Security.User',
  USERNAME: 'P360.Security.Username',
  COMPANY: 'P360.Security.Company',
  COMPANY_TYPE: 'P360.Security.CompanyType',
  COLLABORATOR: 'P360.Security.Collaborator',
  OPTIONS_MENU: 'P360.Security.OptionsMenu',

  SETTINGS_LANGUAGE: 'P360.Settings.Language',
  SETTINGS_FORMAT: 'P360.Settings.Format',
  SETTINGS_PRECISION: 'P360.Settings.Precision',

  CACHE_KEYS: 'P360.CacheKeys',

  IS_LOGGED: 'isLogged',
  AGENT: 'agent',
  REST: 'restEndpoints',
  LANGUAGE: 'language',
  CLIENTS_PER_AGENT: 'clientsPerAgent',
  ITEMS_PER_AGENT: 'itemssPerAgent',
  DISPATCHERS_PER_AGENT: 'dispatchersPerAgent',
  PUSH_ID: 'pushId',
  SALESFORCE_RESULT: 'salesforceResult',
  FILTERS_CLIENTS: 'filterClients',
  FILTERS_LINES: 'filterLines',
  FILTERS_SEGMENTS: 'filterSegments',
  FILTERS_SALESFORCE: 'filterSalesforce',
  FILTERS_DASHBOARD_R: 'filterDashboardR',
  REST_ENDPOINTS: 'endpointCalled',
  LICENSE_INFORMATION: 'licenseInformation',
};
