import { Injectable } from '@angular/core';
import { Globalization } from '@awesome-cordova-plugins/globalization/ngx';
import { Platform } from '@ionic/angular';

@Injectable()
export class HandleGlobalizationService extends Globalization {
  constructor(private platform: Platform) {
    super();
  }

  override getPreferredLanguage(): Promise<{
    value: string;
  }> {
    if (this.platform.is('android') || this.platform.is('ios')) {
      return super.getPreferredLanguage();
    }

    return new Promise((resolve, _) => {
      resolve({ value: 'es' });
    });
  }
}
