import { P360DropdownItem } from 'src/app/models/common';
import { DateType } from 'src/app/models/date-type';
import { FormatType } from 'src/app/models/format-type';
import { AnalyticsCubesRapidFilter } from './store.filters';

export const formatTypeList: FormatType[] = [
  {
    id: 0,
    label: 'TYPE_DOLLAR',
    value: 'currency',
    icon: 'fa-solid fa-dollar-sign',
    textSymbol: '$',
    apiProperty: 'value',
  },
  {
    id: 1,
    label: 'TYPE_UNITS',
    value: 'fixedPoint',
    icon: 'p360 u-icon',
    textSymbol: 'U',
    apiProperty: 'units',
  },
];

export const salesforceSellinFilterList: P360DropdownItem<AnalyticsCubesRapidFilter>[] =
  [
    {
      id: 0,
      label: 'SELLIN_RAPID_FILTER_SALESFORCE_LEVEL_1',
      value: {
        level1: 'city',
        level2: 'line',
        level3: 'product',
        level4: 'item',
      },
      captions: {
        level1: 'CITY',
        level2: 'LINE',
        level3: 'PRODUCT',
        level4: 'ITEM',
      },
    },
    {
      id: 1,
      label: 'SELLIN_RAPID_FILTER_SALESFORCE_LEVEL_2',
      value: {
        level1: 'line',
        level2: 'city',
        level3: 'product',
        level4: 'item',
      },
      captions: {
        level1: 'LINE',
        level2: 'CITY',
        level3: 'PRODUCT',
        level4: 'ITEM',
      },
    },
    {
      id: 2,
      label: 'SELLIN_RAPID_FILTER_SALESFORCE_LEVEL_3',
      value: {
        level1: 'line',
        level2: 'product',
        level3: 'city',
        level4: 'item',
      },
      captions: {
        level1: 'LINE',
        level2: 'PRODUCT',
        level3: 'CITY',
        level4: 'ITEM',
      },
    },
  ];

export const supervisorSellinFilterList: P360DropdownItem<AnalyticsCubesRapidFilter>[] =
  [
    {
      id: 0,
      label: 'SELLIN_RAPID_FILTER_SUPERVISOR_LEVEL_1',
      value: {
        level1: 'region',
        level2: 'city',
        level3: 'line',
        level4: 'product',
      },
      captions: {
        level1: 'REGION',
        level2: 'CITY',
        level3: 'LINE',
        level4: 'PRODUCT',
      },
    },
    {
      id: 1,
      label: 'SELLIN_RAPID_FILTER_SUPERVISOR_LEVEL_2',
      value: {
        level1: 'region',
        level2: 'line',
        level3: 'city',
        level4: 'product',
      },
      captions: {
        level1: 'REGION',
        level2: 'LINE',
        level3: 'CITY',
        level4: 'PRODUCT',
      },
    },
    {
      id: 2,
      label: 'SELLIN_RAPID_FILTER_SUPERVISOR_LEVEL_3',
      value: {
        level1: 'line',
        level2: 'region',
        level3: 'city',
        level4: 'product',
      },
      captions: {
        level1: 'LINE',
        level2: 'REGION',
        level3: 'CITY',
        level4: 'PRODUCT',
      },
    },
    {
      id: 3,
      label: 'SELLIN_RAPID_FILTER_SUPERVISOR_LEVEL_4',
      value: {
        level1: 'line',
        level2: 'product',
        level3: 'region',
        level4: 'city',
      },
      captions: {
        level1: 'LINE',
        level2: 'PRODUCT',
        level3: 'REGION',
        level4: 'CITY',
      },
    },
  ];

export const auditRapidFiltersList: P360DropdownItem<AnalyticsCubesRapidFilter>[] =
  [
    {
      id: 0,
      label: 'AUDIT_CUBE_RAPID_FILTER_LEVEL_1',
      captions: {
        level1: 'LINE',
        level2: 'SUPERVISOR',
        level3: 'COLLABORATOR_ALT',
        level4: 'SEGMENT',
        level5: 'PRODUCT',
      },
      value: {
        level1: 'line',
        level2: 'supervisor',
        level3: 'agent',
        level4: 'segment',
        level5: 'product',
      },
    },
    {
      id: 1,
      label: 'AUDIT_CUBE_RAPID_FILTER_LEVEL_2',
      captions: {
        level1: 'LINE',
        level2: 'SEGMENT',
        level3: 'SUPERVISOR',
        level4: 'COLLABORATOR_ALT',
        level5: 'PRODUCT',
      },
      value: {
        level1: 'line',
        level2: 'segment',
        level3: 'supervisor',
        level4: 'agent',
        level5: 'product',
      },
    },
    {
      id: 2,
      label: 'AUDIT_CUBE_RAPID_FILTER_LEVEL_3',
      captions: {
        level1: 'COLLABORATOR_ALT',
        level2: 'SEGMENT',
        level3: 'PRODUCT',
      },
      value: { level1: 'agent', level2: 'segment', level3: 'product' },
    },
    {
      id: 3,
      label: 'AUDIT_CUBE_RAPID_FILTER_LEVEL_4',
      captions: {
        level1: 'SEGMENT',
        level2: 'COLLABORATOR_ALT',
        level3: 'PRODUCT',
      },
      value: { level1: 'segment', level2: 'agent', level3: 'product' },
    },
  ];

export const collabAuditRapidFiltersList: P360DropdownItem<AnalyticsCubesRapidFilter>[] =
  [
    {
      id: 0,
      label: 'AUDIT_CUBE_COLLAB_RAPID_FILTER_LEVEL_1',
      captions: {
        level1: 'LINE',
        level2: 'SEGMENT',
        level3: 'PRODUCT',
      },
      value: {
        level1: 'line',
        level2: 'segment',
        level3: 'product',
      },
    },
    {
      id: 2,
      label: 'AUDIT_CUBE_COLLAB_RAPID_FILTER_LEVEL_2',
      captions: {
        level1: 'SEGMENT',
        level2: 'PRODUCT',
      },
      value: { level1: 'segment', level2: 'product' },
    },
  ];

export const auditMeasureList: P360DropdownItem<string>[] = [
  { id: 0, label: 'MEASURE_MTD', value: 'MTD' },
  { id: 1, label: 'MEASURE_MAT', value: 'MAT' },
  { id: 2, label: 'MEASURE_YTD', value: 'YTD' },
  { id: 3, label: 'MEASURE_QTD', value: 'QTD' },
  { id: 4, label: 'MEASURE_TRI', value: 'TRI' },
  { id: 5, label: 'MEASURE_MLY', value: 'MLY' },
];

export const regionFilterList: P360DropdownItem<string>[] = [
  { id: 0, label: 'COUNTRY', value: 'TODOS' },
  { id: 1, label: 'COSTA', value: 'COSTA' },
  { id: 2, label: 'SIERRA', value: 'SIERRA' },
  { id: 3, label: 'AUSTRO', value: 'AUSTRO' },
];

export const dateTypeList: DateType[] = [
  {
    id: 0,
    labelToShow: 'DATE_TYPE_MONTHLY',
    label: 'DATE_TYPE_MONTHLY_SHORT',
    value: 'M',
  },
  {
    id: 1,
    labelToShow: 'DATE_TYPE_CUMULATIVE',
    label: 'DATE_TYPE_CUMULATIVE_SHORT',
    value: 'A',
  },
];

export const drilldownPerspectiveList: P360DropdownItem<string>[] = [
  { id: 1, label: 'COLLABORATOR', value: 'FFVV', valueField: 'salesforce' },
  { id: 2, label: 'LINE', value: 'LINEA', valueField: 'line' },
  { id: 3, label: 'REGION', value: 'REGION', valueField: 'region' },
];

export const heatmapMeasureList: P360DropdownItem<string>[] = [
  { id: 2, label: 'APPOINTMENTS', value: 'VISITAS' },
  { id: 0, label: 'SALES', value: 'VENTAS' },
  { id: 1, label: 'RECIPE', value: 'RX' },
  { id: 3, label: 'ORDERS', value: 'PEDIDOS' },
];

export const rxMeasureList: P360DropdownItem<string>[] = [
  { id: 1, label: 'MEASURE_ABS', value: 'ABSOLUTOS' },
  { id: 0, label: 'MEASURE_PERCENT', value: 'PORCENTAJES' },
];
