import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CalendarTypeView } from 'primeng/calendar';

@Component({
  selector: 'app-date-filter',
  templateUrl: './app-date-filter.component.html',
})
export class AppDateFilterComponent {
  @Input() dateFilter? = new Date();
  @Input() yearRange = `${new Date().getFullYear() - 3}:${
    new Date().getFullYear() + 10
  }`;
  @Input() dateFormat = 'M/y';
  @Input() viewMode: CalendarTypeView = 'month';
  @Input() dateInputClass = '';
  @Output() dateChanged = new EventEmitter<Date>();

  constructor() {}

  onDateSelected = () => this.dateChanged.emit(this.dateFilter);

  changeDate = (isUp?: boolean) => {
    if (!this.dateFilter) {
      return;
    }

    const tmpDate = this.dateFilter;
    if (isUp != null && !isUp) {
      tmpDate.setMonth(tmpDate.getMonth() - 1);
    } else {
      tmpDate.setMonth(tmpDate.getMonth() + 1);
    }
    this.dateFilter = new Date(tmpDate);
    this.dateChanged.emit(this.dateFilter);
  };
}
