import { AppGlobal } from './store.global';

export const appStoreGlobalIS: AppGlobal = {
  isSalesforce: true,

  isLoading: false,
  isSaving: false,

  isWeb: false,
  isSmall: false,
  isMedium: false,
  isLarge: false,
  isPortrait: false,

  innerHeight: 0,
  innerWidth: 0,

  connected: true,

  appointmentsToSync: 0,
  ordersToSync: 0,
  customersToSync: 0,

  appColors: {
    primary: '#8A0808',
    secondary: '#FDFAFA',
    lowFulfillment: '#f5564a',
    normalFulfillment: '#ffc721',
    highFulfillment: '#96c95c',
    text: {
      plan: '#DBA901',
      sales: '#000000',
      salesPrev: '#3E3E3E',
      fulfillment: '#0B088A',
      fulfillmentPrev: '#4846A7',
      projection: '#5CB85C',
    },
    charts: {
      plan: '#FDF3DD',
      sales: '#3CBAB2',
      salesPrev: '#CEEEEC',
      fulfillment: '#0B088A',
      fulfillmentPrev: '#E1E0F0',
      projection: '#5CB85C',
    },
  },

  notificationCounter: 0,
  notificationCounterDto: {
    total: 0,
    starred: 0,
    important: 0,
    deleted: 0,
  },
};
