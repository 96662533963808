import { HttpClient } from '@angular/common/http';
import { Camera, CameraResultType } from '@capacitor/camera';
import { Geolocation } from '@capacitor/geolocation';
import { Store } from '@ngrx/store';
import { Observable, from, of, throwError } from 'rxjs';
import { finalize, map, mergeMap, tap, timeout } from 'rxjs/operators';
import { AppMessageService } from 'src/app/core/services/app-message.service';
import { RestService } from 'src/app/core/services/rest.service';
import { AppConstants } from 'src/app/core/utils/app.const';
import { AppStorage } from 'src/app/core/utils/app.storage';
import { AppStorageService } from 'src/app/core/utils/app.storage.service';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { ResponseDto } from 'src/app/models/app.response-dto';
import { Company } from 'src/app/models/company';
import { CustomerComplete } from 'src/app/models/customer';
import { SecurityUser } from 'src/app/models/security/security-user';
import { appStoreGlobalActions } from 'src/app/store/global/store.global.action';
import { appStoreGlobalIS } from 'src/app/store/global/store.global.state';
import { appStoreScreenActions } from 'src/app/store/screen/store.screen.action';

export abstract class BaseService {
  private timeout = 20000;

  constructor(
    protected rest?: RestService,
    protected messageService?: AppMessageService,
    protected storage?: AppStorageService,
    protected http?: HttpClient,
    protected store?: Store<any>,
    protected layoutService?: LayoutService
  ) {}

  handleRestErrorMessage = (
    error: any,
    showWarning = false,
    sticky = false
  ) => {
    const isString = typeof error == 'string';
    const message = isString ? error : error.message;
    console.error('ERROR! ', error);

    const messageService =
      message.indexOf('MESSAGES.NO-CONTENT') > -1 || showWarning
        ? this.messageService?.addWarning
        : this.messageService?.addError;
    messageService(message, {}, sticky);
  };

  cleanRestCache = () => {
    this.storage?.get(AppStorage.CACHE_KEYS).then(async (value: string) => {
      const keys = (value ?? '').split('||');
      const removed = [];
      for (const key of keys) {
        await this.storage?.remove(key);
        removed.push(key);
      }
      this.storage?.set(AppStorage.CACHE_KEYS, removed.join('||'));
    });
  };

  requestGpsPermissions = (method: () => void) => {
    Geolocation.checkPermissions().then((status) => {
      if (
        status.location !== 'granted' ||
        status.coarseLocation !== 'granted'
      ) {
        Geolocation.requestPermissions({
          permissions: ['location', 'coarseLocation'],
        })
          .then(
            () => {},
            () => this.requestGpsPermissions(method)
          )
          .catch(() => this.requestGpsPermissions(method));
      } else {
        method();
      }
    });
  };

  requestCameraPermissions = (method: () => void) => {
    Camera.checkPermissions().then((status) => {
      if (status.camera !== 'granted' || status.photos !== 'granted') {
        Camera.requestPermissions({
          permissions: ['camera', 'photos'],
        })
          .then(
            () => {},
            () => this.requestCameraPermissions(method)
          )
          .catch(() => this.requestCameraPermissions(method));
      } else {
        method();
      }
    });
  };

  getPhoto = async (
    identifier: string | number
  ): Promise<{
    photo: Blob;
    photoUri: string;
    photoPath: string;
    photoName: string;
  }> => {
    const picture = await Camera.getPhoto({
      resultType: CameraResultType.Base64,
      saveToGallery: true,
      height: 800,
      width: 480,
      correctOrientation: true,
    });

    const imageData = picture.base64String;
    const photoUri = imageData;
    const photoPath = `data:image/jpg;base64,${imageData}`;
    const byteChars = atob(imageData);
    const byteNumbers = new Array(byteChars.length);
    for (let i = 0; i < byteChars.length; i++) {
      byteNumbers[i] = byteChars.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const photo = new Blob([byteArray], { type: 'image/jpg' });
    const photoName = `${new Date().getTime()}_${identifier}_img`;

    return { photo, photoUri, photoPath, photoName };
  };

  failOnGetFile = (error: any) => {
    console.error('File Error! ', error);
  };

  getReverseAddress = ({
    lat,
    lon,
  }: {
    lat: number;
    lon: number;
  }): Observable<{
    address?: string;
    countryCode?: string;
    countryName?: string;
    postalCode?: string;
    state?: string;
    county?: string;
    town?: string;
  }> => {
    return this.http
      ?.get('https://nominatim.openstreetmap.org/reverse.php', {
        params: {
          lat,
          lon,
          zoom: 18,
          format: 'jsonv2',
        },
      })
      .pipe(
        map((result) => ({
          address: result['display_name'],
          countryCode: result['address']['country_code'],
          countryName: result['address']['country'],
          postalCode: result['address']['postcode'],
          state: result['address']['state'],
          county: result['address']['county'],
          town: result['address']['town'],
        }))
      );
  };

  getGen0003 = (params: any): Observable<ResponseDto<CustomerComplete>> =>
    this.restGet(AppConstants.REST_ENDPOINTS.GEN_0003, params);

  whoAmI = () => {
    return this.rest
      .get<SecurityUser>(`${AppConstants.SECURITY_HOST}/who-am-i`)
      .pipe(
        tap({
          next: (res) => {
            if (!res.company.theme) {
              res.company.theme = {
                baseColor: 'p360',
                planChart: appStoreGlobalIS.appColors.charts.plan,
                planText: appStoreGlobalIS.appColors.text.plan,
                currentValueChart: appStoreGlobalIS.appColors.charts.sales,
                currentValueText: appStoreGlobalIS.appColors.text.sales,
                previousValueChart: appStoreGlobalIS.appColors.charts.salesPrev,
                previousValueText: appStoreGlobalIS.appColors.text.salesPrev,
                fulfillmentChart: appStoreGlobalIS.appColors.charts.fulfillment,
                fulfillmentText: appStoreGlobalIS.appColors.text.fulfillment,
                previousFulfillmentChart:
                  appStoreGlobalIS.appColors.charts.fulfillmentPrev,
                previousFulfillmentText:
                  appStoreGlobalIS.appColors.text.fulfillmentPrev,
                evolutionChart: appStoreGlobalIS.appColors.charts.projection,
                evolutionText: appStoreGlobalIS.appColors.text.projection,
              };
            }

            this.storage.set(AppStorage.USER, res);
            this.store.dispatch(
              appStoreGlobalActions.setUserAction({ user: res })
            );
            this.storage.set(AppStorage.USERNAME, res.username);
            this.storage.set(AppStorage.COLLABORATOR, res.collaborator);

            this.storage.set(AppStorage.COMPANY, res.company);
            this.layoutService.config.update((config) => ({
              ...config,
              theme: res.company.theme.baseColor ?? 'p360',
            }));
            this.storage.set(
              AppStorage.COMPANY_TYPE,
              res.company.extra.toLowerCase()
            );

            this.store.dispatch(
              appStoreGlobalActions.setNotificationCounterAction({
                notificationCounter: res.notifCounter,
              })
            );

            this.store.dispatch(
              appStoreScreenActions.setClaimsAction({
                claims: res.claims,
              })
            );
          },
        })
      );
  };

  protected restGet = <T = any>(
    endpoint: string,
    pathParams: any,
    urlParams?: any,
    triggerLoading: boolean = false
  ): Observable<T> => {
    if (!this.rest || !this.storage) {
      return throwError(
        () => new Error('You need to pass rest & storage service')
      );
    }

    this.handleTriggerLoading(triggerLoading);
    return from(this.storage.get<Company>(AppStorage.COMPANY)).pipe(
      mergeMap((storage) => {
        const hostUrl = storage?.hostUrl ?? '';
        const companyUri = `${hostUrl}/${AppConstants.MOBILE_HOST}`;
        const urlWithParams = this.rest.addParamsToPath(
          `${companyUri}/${endpoint}`,
          pathParams,
          urlParams
        );

        return from(this.storage.get(urlWithParams)).pipe(
          mergeMap((resultInCache) => {
            let finalObservable = this.rest
              .get(`${companyUri}/${AppConstants.REST_ENDPOINTS.HEALTHCHECK}`)
              .pipe(
                mergeMap((res) => {
                  if (res) {
                    return this.rest
                      .get(urlWithParams)
                      .pipe(timeout(this.timeout));
                  }
                  return throwError(() => new Error('MESSAGE.500_GENERIC'));
                })
              );
            if (!this.isEndpointIgnored(endpoint) && resultInCache) {
              finalObservable = of(resultInCache);
            }
            return finalObservable.pipe(finalize(this.finalizeLoading));
          })
        );
      }),
      finalize(this.finalizeLoading)
    );
  };

  protected restPost = <T = any>(
    endpoint: string,
    body: any,
    pathParams?: any,
    urlParams?: any,
    triggerLoading: boolean = false
  ): Observable<ResponseDto<T>> => {
    if (!this.rest || !this.storage) {
      return throwError(
        () => new Error('You need to pass rest & storage service')
      );
    }

    this.handleTriggerLoading(triggerLoading);
    return from(this.storage.get<Company>(AppStorage.COMPANY)).pipe(
      mergeMap((storage) => {
        const hostUrl = storage?.hostUrl ?? '';
        const companyUri = `${hostUrl}/${AppConstants.MOBILE_HOST}`;

        return this.rest
          .get(`${companyUri}/${AppConstants.REST_ENDPOINTS.HEALTHCHECK}`)
          .pipe(
            mergeMap((res) => {
              if (res) {
                return this.rest.post(
                  `${companyUri}/${endpoint}`,
                  body,
                  urlParams,
                  pathParams
                );
              }
              return throwError(() => new Error('MESSAGE.500_GENERIC'));
            })
          );
      }),
      finalize(this.finalizeLoading)
    );
  };

  protected restDelete = <T = any>(
    endpoint: string,
    urlParams: any,
    params?: any,
    triggerLoading: boolean = false
  ): Observable<T> => {
    if (!this.storage)
      if (!this.rest || !this.storage) {
        return throwError(
          () => new Error('You need to pass rest & storage service')
        );
      }

    this.handleTriggerLoading(triggerLoading);
    return from(this.storage.get<Company>(AppStorage.COMPANY)).pipe(
      mergeMap((storage) => {
        const hostUrl = storage?.hostUrl ?? '';
        const companyUri = `${hostUrl}/${AppConstants.MOBILE_HOST}`;

        return this.rest
          .get(`${companyUri}/${AppConstants.REST_ENDPOINTS.HEALTHCHECK}`)
          .pipe(
            timeout(this.timeout),
            mergeMap((res) => {
              if (res) {
                return this.rest
                  .delete(`${companyUri}/${endpoint}`, urlParams, params)
                  .pipe(timeout(this.timeout));
              }
              return throwError(() => new Error('MESSAGE.500_GENERIC'));
            })
          );
      }),
      finalize(this.finalizeLoading)
    );
  };

  private isEndpointIgnored = (endpoint: string) =>
    endpoint === AppConstants.REST_ENDPOINTS.HEALTHCHECK ||
    endpoint === AppConstants.REST_ENDPOINTS.GEN_0000 ||
    endpoint === AppConstants.REST_ENDPOINTS.GEN_0001 ||
    endpoint === AppConstants.REST_ENDPOINTS.GEN_0002 ||
    endpoint === AppConstants.REST_ENDPOINTS.GEN_0003 ||
    endpoint === AppConstants.REST_ENDPOINTS.GEN_0019 ||
    endpoint === AppConstants.REST_ENDPOINTS.GEN_0021 ||
    endpoint === AppConstants.REST_ENDPOINTS.GEN_0022 ||
    endpoint === AppConstants.REST_ENDPOINTS.GEN_0030 ||
    endpoint === AppConstants.REST_ENDPOINTS.GEN_0033 || // TODO: Not needed anymore
    endpoint === AppConstants.REST_ENDPOINTS.GEN_0036 ||
    endpoint === AppConstants.REST_ENDPOINTS.SELLIN_0008 ||
    endpoint === AppConstants.REST_ENDPOINTS.SELLIN_0028 ||
    endpoint === AppConstants.REST_ENDPOINTS.MAP_PLANNING;

  private handleTriggerLoading = (triggerLoading: boolean = false) => {
    if (triggerLoading) {
      this.store?.dispatch(
        appStoreGlobalActions.setIsLoadingAction({
          isLoading: true,
        })
      );
    }
  };

  private finalizeLoading = () => {
    setTimeout(() => {
      this.store?.dispatch(
        appStoreGlobalActions.setIsLoadingAction({
          isLoading: false,
        })
      );
    }, 0);
  };
}
