import { createAction, props } from '@ngrx/store';
import { AppSettingsFormat, AppSettingsLanguage } from './store.settings';

export const appStoreSettingsActions = {
  setPrecisionAction: createAction(
    'SET_PRECISION',
    props<{ precision: number }>()
  ),

  setFormatAction: createAction(
    'SET_FORMAT',
    props<{ format: AppSettingsFormat }>()
  ),

  setLanguageAction: createAction(
    'SET_LANGUAGE',
    props<{ language: AppSettingsLanguage }>()
  ),

  setScaleAction: createAction('SET_SCALE', props<{ scale: number }>()),
};
