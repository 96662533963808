import { Component, OnInit, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SelectButtonChangeEvent } from 'primeng/selectbutton';
import { BaseFiltersComponent } from 'src/app/core/base/base-filters.component';
import { DateType, DateTypeValue } from 'src/app/models/date-type';
import { appStoreFiltersActions } from 'src/app/store/filters/store.filters.action';
import { AppStorageService } from 'src/app/core/utils/app.storage.service';

@Component({
  selector: '[app-filters-drilldown]',
  templateUrl: './app.filters.drilldown.component.html',
})
export class AppFiltersDrilldownComponent
  extends BaseFiltersComponent
  implements OnInit
{
  dateTypeOptions: DateType[] = [];
  dateTypeSelected?: DateTypeValue;

  constructor(
    override store: Store<any>,
    override storage: AppStorageService,
    override renderer: Renderer2,
    override translate: TranslateService
  ) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.store
      .select('filters', 'dateTypeList')
      .subscribe((dateTypeOptions) => {
        this.dateTypeOptions = dateTypeOptions;
      });

    this.store
      .select('filters', 'dateTypeSelected')
      .subscribe((dateTypeSelected) => {
        this.dateTypeSelected = dateTypeSelected;
      });
  }

  onDateTypeChange = ({ value: dateTypeSelected }: SelectButtonChangeEvent) => {
    this.store.dispatch(
      appStoreFiltersActions.setDateTypeAction({
        dateTypeSelected,
      })
    );
  };

  customClearFilters = () => {
    this.store.dispatch(
      appStoreFiltersActions.setDateTypeAction({
        dateTypeSelected: this.dateTypeOptions[0].value!,
      })
    );
  };
}
