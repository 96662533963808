import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Dropdown, DropdownChangeEvent } from 'primeng/dropdown';
import { BaseFiltersComponent } from 'src/app/core/base/base-filters.component';
import { P360DropdownItem } from 'src/app/models/common';
import { appStoreFiltersActions } from 'src/app/store/filters/store.filters.action';
import { AppStorageService } from 'src/app/core/utils/app.storage.service';

@Component({
  selector: '[app-filters-kpis-heatmap]',
  templateUrl: './app.filters.kpis.heatmap.component.html',
})
export class AppFiltersKpisHeatmapComponent
  extends BaseFiltersComponent
  implements OnInit
{
  @ViewChild('measureFilter') measureFilter?: Dropdown;

  measureOptions: P360DropdownItem<string>[] = [];
  measureSelected?: string;

  constructor(
    override store: Store<any>,
    override storage: AppStorageService,
    override renderer: Renderer2,
    override translate: TranslateService
  ) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.store
      .select('filters', 'kpis', 'heatmapMeasureList')
      .subscribe((heatmapMeasureList) => {
        this.measureOptions = heatmapMeasureList;
      });

    this.store
      .select('filters', 'kpis', 'heatmapMeasureOption')
      .subscribe((heatmapMeasureOption) => {
        this.measureSelected = heatmapMeasureOption.value;
      });
  }

  onMeasureChange = ({ value }: DropdownChangeEvent) => {
    const filterOption = this.measureOptions.find(
      (item) => item.value === value
    );

    if (filterOption) {
      this.store.dispatch(
        appStoreFiltersActions.setKpisMeasureOptionAction({
          heatmapMeasureOption: filterOption,
        })
      );
    }
  };

  override customOnLineChange = ({ value }: DropdownChangeEvent) => {
    const filterOption = this.lineOptions.find(
      (item) => item.id === value
    )?.value;

    this.store.dispatch(
      appStoreFiltersActions.setKpisHeatmapMeasureTypeOptionAction({
        kpiType: 'LINEA',
        kpiLabel: filterOption?.name,
        kpiValue: filterOption?.code,
      })
    );
  };

  override customOnSegmentChange = ({ value }: DropdownChangeEvent) => {
    const filterOption = this.segmentOptions.find(
      (item) => item.id === value
    )?.value;

    this.store.dispatch(
      appStoreFiltersActions.setKpisHeatmapMeasureTypeOptionAction({
        kpiType: 'SEGMENTO',
        kpiLabel: filterOption?.name,
        kpiValue: filterOption?.code,
      })
    );
  };

  override customOnCollaboratorChange = ({ value }: DropdownChangeEvent) => {
    const filterOption = this.collaboratorOptions.find(
      (item) => item.id === value
    )?.value;

    this.store.dispatch(
      appStoreFiltersActions.setKpisHeatmapMeasureTypeOptionAction({
        kpiType: 'REPRESENTANTE',
        kpiLabel: filterOption?.name,
        kpiValue: `${filterOption?.code}`,
      })
    );
  };

  override customClearFilters = () => {
    this.store.dispatch(appStoreFiltersActions.clearKpisMeasureFilterAction());
    this.store.dispatch(
      appStoreFiltersActions.clearKpisHeatmapMeasureTypeFilterAction()
    );
  };
}
