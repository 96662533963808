import { Action, createReducer, on } from '@ngrx/store';
import { AppSettings } from './store.settings';
import { appStoreSettingsActions } from './store.settings.action';
import { appStoreSettingsIS } from './store.settings.state';

const appStoreSettingsRegister = createReducer(
  appStoreSettingsIS,

  on(appStoreSettingsActions.setPrecisionAction, (state, { precision }) => ({
    ...state,
    precision,
  })),

  on(appStoreSettingsActions.setFormatAction, (state, { format }) => ({
    ...state,
    format,
  })),

  on(appStoreSettingsActions.setLanguageAction, (state, { language }) => ({
    ...state,
    language,
  })),

  on(appStoreSettingsActions.setScaleAction, (state, { scale }) => ({
    ...state,
    scale,
  }))
);

export const appStoreSettingsReducer = (
  state: AppSettings,
  action: Action
): any => appStoreSettingsRegister(state, action);
