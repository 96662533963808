import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class AppMessageService {
  constructor(protected messageService: MessageService) {}

  addSuccess = (summary: string, data?: any, sticky: boolean = false) => {
    this.messageService.add({
      icon: 'pi-check',
      severity: 'success',
      summary,
      sticky,
      data,
    });
  };

  addInfo = (summary: string, data?: any, sticky: boolean = false) => {
    this.messageService.add({
      icon: 'pi-info-circle',
      severity: 'info',
      summary,
      sticky,
      data,
    });
  };

  addWarning = (summary: string, data?: any, sticky: boolean = false) => {
    this.messageService.add({
      icon: 'pi-exclamation-triangle',
      severity: 'warn',
      summary,
      sticky,
      data,
    });
  };

  addError = (summary: string, data?: any, sticky: boolean = false) => {
    this.messageService.add({
      icon: 'pi-times-circle',
      severity: 'error',
      summary,
      sticky,
      data,
    });
  };
}
