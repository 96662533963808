import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Dropdown, DropdownChangeEvent } from 'primeng/dropdown';
import { BaseFiltersComponent } from 'src/app/core/base/base-filters.component';
import { P360DropdownItem } from 'src/app/models/common';
import { AnalyticsCubesRapidFilter } from 'src/app/store/filters/store.filters';
import { appStoreFiltersActions } from 'src/app/store/filters/store.filters.action';
import { AppStorageService } from 'src/app/core/utils/app.storage.service';

@Component({
  selector: '[app-filters-analytics-cubes-audit]',
  templateUrl: './app.filters.analytics-cubes.audit.component.html',
})
export class AppFiltersAnalyticsCubesAuditComponent
  extends BaseFiltersComponent
  implements OnInit
{
  @ViewChild('measureFilter') measureFilter?: Dropdown;

  collabRapidFiltersOptions: P360DropdownItem<AnalyticsCubesRapidFilter>[] = [];
  collabRapidFiltersSelected?: number;
  rapidFiltersOptions: P360DropdownItem<AnalyticsCubesRapidFilter>[] = [];
  rapidFiltersSelected?: number;
  measureOptions: P360DropdownItem<string>[] = [];
  measureSelected?: string;

  constructor(
    override store: Store<any>,
    override storage: AppStorageService,
    override renderer: Renderer2,
    override translate: TranslateService
  ) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.store
      .select('filters', 'analyticsCubes', 'collabAuditRapidFiltersList')
      .subscribe((auditRapidFiltersList) => {
        this.collabRapidFiltersOptions = auditRapidFiltersList;
      });

    this.store
      .select('filters', 'analyticsCubes', 'collabAuditRapidFiltersOption')
      .subscribe((auditRapidFiltersOption) => {
        this.collabRapidFiltersSelected = auditRapidFiltersOption.id;
      });

    this.store
      .select('filters', 'analyticsCubes', 'auditRapidFiltersList')
      .subscribe((auditRapidFiltersList) => {
        this.rapidFiltersOptions = auditRapidFiltersList;
      });

    this.store
      .select('filters', 'analyticsCubes', 'auditRapidFiltersOption')
      .subscribe((auditRapidFiltersOption) => {
        this.rapidFiltersSelected = auditRapidFiltersOption.id;
      });

    this.store
      .select('filters', 'analyticsCubes', 'auditMeasureList')
      .subscribe((auditMeasureList) => {
        this.measureOptions = auditMeasureList;
      });

    this.store
      .select('filters', 'analyticsCubes', 'auditMeasureOption')
      .subscribe((auditMeasureOption) => {
        this.measureSelected = auditMeasureOption.value;
      });
  }

  handleRapidFilterChange = ({ value }: DropdownChangeEvent) => {
    const options = this.isSalesforce
      ? this.collabRapidFiltersOptions
      : this.rapidFiltersOptions;
    const found = options.find((item) => item.id === value);

    const name = this.isSalesforce
      ? 'collabAuditRapidFiltersOption'
      : 'auditRapidFiltersOption';
    this.store.dispatch(
      appStoreFiltersActions.setAnalyticsCubesAuditSelectedAction({
        name,
        option: found,
      })
    );
  };

  handleMeasureChange = ({ value }: DropdownChangeEvent) => {
    const auditMeasureOption = this.measureOptions.find(
      (item) => item.value === value
    );
    if (auditMeasureOption) {
      this.store.dispatch(
        appStoreFiltersActions.setAnalyticsCubesAuditMeasureOptionAction({
          auditMeasureOption,
        })
      );
    }
  };

  override customClearFilters = () => {
    const name = this.isSalesforce
      ? 'collabAuditRapidFiltersOption'
      : 'auditRapidFiltersOption';
    this.store.dispatch(
      appStoreFiltersActions.clearAnalyticsCubesAuditFilterAction({ name })
    );
    this.store.dispatch(
      appStoreFiltersActions.clearAnalyticsCubesAuditMeasureFilterAction()
    );
  };
}
