import { Component, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { BaseFiltersComponent } from 'src/app/core/base/base-filters.component';
import { appStoreFiltersActions } from 'src/app/store/filters/store.filters.action';
import { AppStorageService } from 'src/app/core/utils/app.storage.service';

@Component({
  selector: '[app-filters-analytics-cubes-kpi]',
  templateUrl: './app.filters.analytics-cubes.kpi.component.html',
})
export class AppFiltersAnalyticsCubesKpiComponent extends BaseFiltersComponent {
  constructor(
    override store: Store<any>,
    override storage: AppStorageService,
    override renderer: Renderer2,
    override translate: TranslateService
  ) {
    super();
  }

  override customOnLineChange = ({ value }: DropdownChangeEvent) => {
    const filterOption = this.lineOptions.find(
      (item) => item.id === value
    )?.value;

    this.store.dispatch(
      appStoreFiltersActions.setAnalyticsCubesKpiOptionAction({
        kpiType: 'LINEA',
        kpiLabel: filterOption?.name,
        kpiValue: filterOption?.code,
      })
    );
  };

  override customOnSegmentChange = ({ value }: DropdownChangeEvent) => {
    const filterOption = this.segmentOptions.find(
      (item) => item.id === value
    )?.value;

    this.store.dispatch(
      appStoreFiltersActions.setAnalyticsCubesKpiOptionAction({
        kpiType: 'SEGMENTO',
        kpiLabel: filterOption?.name,
        kpiValue: filterOption?.code,
      })
    );
  };

  override customOnCollaboratorChange = ({ value }: DropdownChangeEvent) => {
    const filterOption = this.collaboratorOptions.find(
      (item) => item.id === value
    )?.value;

    this.store.dispatch(
      appStoreFiltersActions.setAnalyticsCubesKpiOptionAction({
        kpiType: 'REPRESENTANTE',
        kpiLabel: filterOption?.name,
        kpiValue: `${filterOption?.code}`,
      })
    );
  };

  override customOnRegionChange = ({ value }: DropdownChangeEvent) => {
    const filterOption = this.regionOptions.find(
      (item) => item.value === value
    );

    this.store.dispatch(
      appStoreFiltersActions.setAnalyticsCubesKpiOptionAction({
        kpiType: 'REGION',
        kpiLabel: filterOption?.label,
        kpiValue: filterOption?.value,
      })
    );
  };

  override customClearFilters = () => {
    this.store.dispatch(
      appStoreFiltersActions.clearAnalyticsCubesKpiFilterAction()
    );
  };
}
