import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { formatNumber } from 'devextreme/localization';
import { BaseComponent } from 'src/app/core/base/base.component';
import { FormatTypeApi } from 'src/app/models/format-type';
import { PharmaColorsModel } from 'src/app/models/pharma.model';

@Component({
  selector: 'app-fulfillment',
  templateUrl: 'app-fulfillment.html',
})
export class AppFulfillmentComponent extends BaseComponent {
  @Input() value = 0;
  @Input() endValue = 100;
  @Input() height?: number;
  @Input() width?: number;
  @Input() barWidth?: number = 15;

  @Input() showFulfillment = false;
  @Input() formatIcon = '';
  @Input() formatType: FormatTypeApi = 'units';
  @Input() plan = 0;
  @Input() sales = 0;

  pharmaColors: PharmaColorsModel = new PharmaColorsModel();

  constructor(override store: Store<any>) {
    super();
  }

  get formattedPlan() {
    const format =
      this.formatType === 'units' ? this.unitsFormat : this.valueFormat;
    return formatNumber(this.plan, {
      type: format,
      precision: this.settings.precision,
    });
  }

  get formattedSales() {
    const format =
      this.formatType === 'units' ? this.unitsFormat : this.valueFormat;
    return formatNumber(this.sales, {
      type: format,
      precision: this.settings.precision,
    });
  }

  get textCloudBgColor() {
    if (this.value < 80) {
      return `${this.pharmaColors.lowFulfillment}44`;
    } else if (this.value < 100) {
      return `${this.pharmaColors.normalFulfillment}44`;
    } else if (this.value > 100) {
      return `${this.pharmaColors.highFulfillment}44`;
    }
    return '#E6E6E6';
  }
}
