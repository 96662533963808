import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseComponent } from 'src/app/core/base/base.component';
import { MenuService } from 'src/app/layout/app.menu.service';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { appStoreFiltersActions } from 'src/app/store/filters/store.filters.action';

@Component({
  selector: 'app-filters',
  templateUrl: './app.filters.component.html',
})
export class AppFiltersComponent extends BaseComponent implements OnInit {
  hideFilters = false;
  hideDateFilter = false;

  constructor(
    public layoutService: LayoutService,
    public menuService: MenuService,
    override store: Store<any>
  ) {
    super();
  }

  override ngOnInit(): void {
    this.store?.select('screen').subscribe((screen) => {
      this.hideFilters = screen.hideFilters;
      this.hideDateFilter = screen.hideDateFilter;
    });

    super.ngOnInit();
  }

  onFilterButtonClick() {
    if (!this.hideFilters) {
      this.layoutService.showFiltersSidebar();
    }
  }

  handleDateChanged = ($ev: Date) => {
    this.store.dispatch(
      appStoreFiltersActions.setDateSelectedAction({ dateSelected: $ev })
    );
  };

  handleClearFilters = () => {
    this.store.dispatch(
      appStoreFiltersActions.setDateSelectedAction({
        dateSelected: new Date(),
      })
    );
  };

  get visible(): boolean {
    return this.layoutService.state.filtersSidebarVisible;
  }
  set visible(_val: boolean) {
    this.layoutService.state.filtersSidebarVisible = _val;
  }

  get menuVisible(): boolean {
    return this.layoutService.state.staticMenuMobileActive;
  }
}
