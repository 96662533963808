import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseComponent } from 'src/app/core/base/base.component';

@Component({
  selector: 'app-confirm-dialog, [app-confirm-dialog]',
  templateUrl: './app-confirm-dialog.component.html',
})
export class AppConfirmDialogComponent extends BaseComponent {
  @Input({ required: true }) key: string;
  @Input({ required: true }) headline: string;
  @Input() subHeadline = '';

  constructor(override store: Store<any>) {
    super();
  }
}
