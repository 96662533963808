import { Action, createReducer, on } from '@ngrx/store';
import { AppFilters } from './store.filters';
import { appStoreFiltersActions } from './store.filters.action';
import { appStoreFiltersIS } from './store.filters.state';

const appStoreFiltersRegister = createReducer(
  appStoreFiltersIS,

  on(
    appStoreFiltersActions.setDateSelectedAction,
    (state, { dateSelected }) => ({
      ...state,
      dateSelected,
    })
  ),

  on(
    appStoreFiltersActions.setFormatTypeAction,
    (state, { formatTypeSelected }) => {
      const formatTypeOption = state.formatTypeList?.find(
        (item) => item.value === formatTypeSelected
      );
      return {
        ...state,
        formatTypeOption,
        formatTypeSelected,
        formatTypeApi: formatTypeOption?.apiProperty,
      };
    }
  ),

  on(
    appStoreFiltersActions.setDateTypeAction,
    (state, { dateTypeSelected }) => ({
      ...state,
      dateTypeOption: state.dateTypeList?.find(
        (item) => item.value === dateTypeSelected
      ),
      dateTypeSelected,
    })
  ),

  on(
    appStoreFiltersActions.setGlobalCatalogListAction,
    (state, { globalCatalogList }) => ({
      ...state,
      globalCatalogList,
    })
  ),

  on(
    appStoreFiltersActions.setGlobalProductItemListAction,
    (state, { productItemList }) => ({
      ...state,
      productItemList,
    })
  ),

  on(
    appStoreFiltersActions.setGlobalDispatcherListAction,
    (state, { dispatcherList }) => ({
      ...state,
      dispatcherList,
    })
  ),

  on(
    appStoreFiltersActions.setCollaboratorListAction,
    (state, { collaboratorFilterList }) => ({
      ...state,
      collaboratorFilterList,
      collaboratorFilterOption: collaboratorFilterList[0].value,
    })
  ),

  on(
    appStoreFiltersActions.setCollaboratorOptionAction,
    (state, { collaboratorFilterOption }) => ({
      ...state,
      collaboratorFilterOption,
    })
  ),

  on(appStoreFiltersActions.clearCollaboratorFilterAction, (state) => ({
    ...state,
    collaboratorFilterOption: state.collaboratorFilterList?.[0].value,
  })),

  on(
    appStoreFiltersActions.setClientListAction,
    (state, { clientFilterList }) => ({
      ...state,
      clientFilterList,
      clientFilterOption: clientFilterList[0].value,
    })
  ),

  on(
    appStoreFiltersActions.setClientOptionAction,
    (state, { clientFilterOption }) => ({
      ...state,
      clientFilterOption,
    })
  ),

  on(appStoreFiltersActions.clearClientFilterAction, (state) => ({
    ...state,
    clientFilterOption: state.clientFilterList
      ? state.clientFilterList[0].value
      : undefined,
  })),

  on(
    appStoreFiltersActions.setClientForFiltersListAction,
    (state, { clientForFiltersFilterList }) => ({
      ...state,
      clientForFiltersFilterList,
      clientForFiltersFilterOption: clientForFiltersFilterList[0].value,
    })
  ),

  on(
    appStoreFiltersActions.setClientForFiltersOptionAction,
    (state, { clientForFiltersFilterOption }) => ({
      ...state,
      clientForFiltersFilterOption,
    })
  ),

  on(appStoreFiltersActions.clearClientForFiltersFilterAction, (state) => ({
    ...state,
    clientForFiltersFilterOption: state.clientForFiltersFilterList
      ? state.clientForFiltersFilterList[0].value
      : undefined,
  })),

  on(appStoreFiltersActions.setLineListAction, (state, { lineFilterList }) => ({
    ...state,
    lineFilterList,
    lineFilterOption: lineFilterList.find(
      (item) => item.value?.code === 'TODOS'
    )?.value,
  })),

  on(
    appStoreFiltersActions.setLineOptionAction,
    (state, { lineFilterOption }) => ({
      ...state,
      lineFilterOption,
    })
  ),

  on(appStoreFiltersActions.clearLineFilterAction, (state) => ({
    ...state,
    lineFilterOption: state.lineFilterList
      ? state.lineFilterList.find((item) => item.value?.code === 'TODOS')?.value
      : undefined,
  })),

  on(
    appStoreFiltersActions.setSegmentListAction,
    (state, { segmentFilterList }) => ({
      ...state,
      segmentFilterList,
      segmentFilterOption: segmentFilterList.find(
        (item) => item.value?.code === 'TODOS'
      )?.value,
    })
  ),

  on(
    appStoreFiltersActions.setSegmentOptionAction,
    (state, { segmentFilterOption }) => ({
      ...state,
      segmentFilterOption,
    })
  ),

  on(appStoreFiltersActions.clearSegmentFilterAction, (state) => ({
    ...state,
    segmentFilterOption: state.segmentFilterList
      ? state.segmentFilterList.find((item) => item.value?.code === 'TODOS')
          ?.value
      : undefined,
  })),

  on(
    appStoreFiltersActions.setRegionFilterOptionAction,
    (state, { regionFilterOption }) => ({
      ...state,
      regionFilterOption,
    })
  ),

  on(appStoreFiltersActions.clearRegionFilterAction, (state) => ({
    ...state,
    regionFilterOption: state.regionFilterList
      ? state.regionFilterList[0]
      : undefined,
  })),

  on(
    appStoreFiltersActions.setAnalyticsCubesSellinSelectedAction,
    (state, { name, option }) => ({
      ...state,
      analyticsCubes: {
        ...state.analyticsCubes,
        [name]: option,
      },
    })
  ),

  on(
    appStoreFiltersActions.clearAnalyticsCubesSellinFilterAction,
    (state, { name }) => ({
      ...state,
      analyticsCubes: {
        ...state.analyticsCubes,
        [`${name}Option`]: (state.analyticsCubes as any)[`${name}List`][0],
      },
    })
  ),

  on(
    appStoreFiltersActions.setAnalyticsCubesAuditSelectedAction,
    (state, { name, option }) => ({
      ...state,
      analyticsCubes: {
        ...state.analyticsCubes,
        [name]: option,
      },
    })
  ),

  on(
    appStoreFiltersActions.clearAnalyticsCubesAuditFilterAction,
    (state, { name }) => ({
      ...state,
      analyticsCubes: {
        ...state.analyticsCubes,
        [`${name}Option`]: (state.analyticsCubes as any)[`${name}List`][0],
      },
    })
  ),

  on(
    appStoreFiltersActions.setAnalyticsCubesAuditMeasureOptionAction,
    (state, { auditMeasureOption }) => ({
      ...state,
      analyticsCubes: {
        ...state.analyticsCubes,
        auditMeasureOption,
      },
    })
  ),

  on(
    appStoreFiltersActions.clearAnalyticsCubesAuditMeasureFilterAction,
    (state) => ({
      ...state,
      analyticsCubes: {
        ...state.analyticsCubes,
        auditMeasureOption: state.analyticsCubes?.auditMeasureList
          ? state.analyticsCubes?.auditMeasureList[0]
          : undefined,
      },
    })
  ),

  on(
    appStoreFiltersActions.setAnalyticsCubesKpiOptionAction,
    (state, { kpiType, kpiLabel, kpiValue }) => ({
      ...state,
      analyticsCubes: {
        ...state.analyticsCubes,
        kpiType,
        kpiLabel,
        kpiValue,
      },
    })
  ),

  on(appStoreFiltersActions.clearAnalyticsCubesKpiFilterAction, (state) => ({
    ...state,
    analyticsCubes: {
      ...state.analyticsCubes,
      kpiType: 'TODOS',
      kpiLabel: 'TODOS',
      kpiValue: 'TODOS',
    },
  })),

  on(
    appStoreFiltersActions.setDrilldownPerspectiveOptionAction,
    (state, { drilldownPerspectiveOption }) => ({
      ...state,
      drilldownPerspectiveOption,
    })
  ),

  on(appStoreFiltersActions.clearDrilldownPerspectiveFilterAction, (state) => ({
    ...state,
    drilldownPerspectiveOption: state.drilldownPerspectiveList?.[1],
  })),

  on(
    appStoreFiltersActions.setKpisMeasureOptionAction,
    (state, { heatmapMeasureOption }) => ({
      ...state,
      kpis: {
        ...state.kpis,
        heatmapMeasureOption,
      },
    })
  ),

  on(appStoreFiltersActions.clearKpisMeasureFilterAction, (state) => ({
    ...state,
    heatmapMeasureOption: state.kpis?.heatmapMeasureList?.[1],
  })),

  on(
    appStoreFiltersActions.setKpisHeatmapMeasureTypeOptionAction,
    (state, { kpiType, kpiLabel, kpiValue }) => ({
      ...state,
      kpis: {
        ...state.kpis,
        heatmapKpiType: kpiType,
        heatmapKpiLabel: kpiLabel,
        heatmapKpiValue: kpiValue,
      },
    })
  ),

  on(
    appStoreFiltersActions.clearKpisHeatmapMeasureTypeFilterAction,
    (state) => ({
      ...state,
      kpis: {
        ...state.kpis,
        heatmapKpiType: 'TODOS',
        heatmapKpiLabel: 'TODOS',
        heatmapKpiValue: 'TODOS',
      },
    })
  ),

  on(
    appStoreFiltersActions.setRxMeasureOptionAction,
    (state, { rxMeasureOption }) => ({
      ...state,
      rxMeasureOption,
    })
  ),

  on(appStoreFiltersActions.clearRxMeasureFilterAction, (state) => ({
    ...state,
    rxMeasureOption: state.rxMeasureList?.[0],
  })),

  on(
    appStoreFiltersActions.setRxMeasureTypeOptionAction,
    (state, { rxType, rxLabel, rxValue }) => ({
      ...state,
      rxType,
      rxLabel,
      rxValue,
    })
  ),

  on(appStoreFiltersActions.clearRxMeasureTypeFilterAction, (state) => ({
    ...state,
    rxType: 'TODOS',
    rxLabel: 'TODOS',
    rxValue: 'TODOS',
  }))
);

export const appStoreFiltersReducer = (
  state: AppFilters,
  action: Action
): any => appStoreFiltersRegister(state, action);
