<div class="w-full" *ngIf="isSmall">
  <p-divider align="center" layout="horizontal">
    <span class="p-tag">&amp;</span>
  </p-divider>
</div>

<div class="flex justify-content-center">
  <div class="col-12 md:col-4 flex flex-column">
    <label class="font-bold" for="measureFilter">{{ "MEASURE" | translate }}</label>

    <div class="w-full">
      <p-dropdown
        validation
        #measureFilter
        [(ngModel)]="measureSelected"
        (onChange)="onMeasureChange($event)"
        name="measureFilter"
        [options]="measureOptions"
        optionLabel="label"
        optionValue="value"
        styleClass="w-full"
        appendTo="body"
      >
        <ng-template let-item pTemplate="selectedItem">
          <span [translate]="item.label"></span>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <span [translate]="item.label"></span>
        </ng-template>
      </p-dropdown>
    </div>
  </div>
</div>

<div class="w-full">
  <p-divider align="center" layout="horizontal">
    <span class="p-tag">&amp;</span>
  </p-divider>
</div>

<div class="col-12 flex flex-wrap justify-content-center p-0 border-round border-1 border-dashed surface-border md:border-none">
  <div class="col-12 md:col p-2 md:p-0 flex flex-column">
    <label class="font-bold" for="lineFilter">{{ "LINE" | translate }}</label>

    <div class="w-full">
      <p-dropdown validation #lineFilter name="lineFilter" [(ngModel)]="lineSelected" (onChange)="onLineChange($event)" [options]="lineOptions" optionLabel="label" optionValue="id" styleClass="w-full" appendTo="body">
        <ng-template let-item pTemplate="selectedItem">
          <span [translate]="item.label"></span>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <span [translate]="item.label"></span>
        </ng-template>
      </p-dropdown>
    </div>
  </div>

  <div [ngClass]="{ 'col-12 p-0': isSmall }">
    <p-divider align="center" [layout]="isSmall ? 'horizontal' : 'vertical'" type="dashed">
      <span class="p-tag" *ngIf="isSmall" translate="FILTERS_OR"></span>
    </p-divider>
  </div>

  <div class="col-12 md:col p-2 md:p-0 flex flex-column">
    <label class="font-bold" for="segmentFilter">{{ "SEGMENT" | translate }}</label>

    <div class="w-full">
      <p-dropdown
        validation
        #segmentFilter
        name="segmentFilter"
        [(ngModel)]="segmentSelected"
        (onChange)="onSegmentChange($event)"
        [options]="segmentOptions"
        optionLabel="label"
        optionValue="id"
        styleClass="w-full"
        appendTo="body"
      >
        <ng-template let-item pTemplate="selectedItem">
          <span [translate]="item.label"></span>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <span [translate]="item.label"></span>
        </ng-template>
      </p-dropdown>
    </div>
  </div>

  <div class="col-12 p-0" [ngClass]="{ 'pb-2': !isSmall }">
    <p-divider align="center" layout="horizontal" type="dashed" styleClass="{{ !isSmall ? 'm-0' : '' }}">
      <span class="p-tag" translate="FILTERS_OR"></span>
    </p-divider>
  </div>

  <div class="col-12 md:col-6 p-2 md:p-0 flex flex-column">
    <label class="font-bold" for="collaboratorFilter">{{ "COLLABORATOR" | translate }}</label>

    <div class="w-full">
      <p-dropdown
        validation
        #collaboratorFilter
        name="collaboratorFilter"
        [(ngModel)]="collaboratorSelected"
        (onChange)="onCollaboratorChange($event)"
        [options]="collaboratorOptions"
        optionLabel="label"
        optionValue="id"
        styleClass="w-full"
        appendTo="body"
      >
        <ng-template let-item pTemplate="selectedItem">
          <span [translate]="item.label"></span>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <span [translate]="item.label"></span>
        </ng-template>
      </p-dropdown>
    </div>
  </div>
</div>

<div class="text-right mt-4">
  <p-button icon="pi pi-eraser text-2xl" type="button" [outlined]="true" [rounded]="true" severity="danger" [text]="true" (onClick)="clearFilters()"></p-button>
</div>
