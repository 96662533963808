import { createAction, props } from '@ngrx/store';
import { CollaboratorFull } from 'src/app/models/collaborator';
import { Company } from 'src/app/models/company';
import { SecurityUser } from 'src/app/models/security/security-user';
import { NotificationCounterDTO } from 'src/app/models/security/notification-counter-dto';

const appStoreGlobalLabels = {
  /* eslint-disable @typescript-eslint/naming-convention */
  SET_USER: 'SET_USER',
  SET_COLLABORATOR: 'SET_COLLABORATOR',
  SET_COMPANY: 'SET_COMPANY',

  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_IS_SAVING: 'SET_IS_SAVING',

  SET_SCREEN_ID: 'SET_SCREEN_ID',
  SET_TITLE: 'SET_TITLE',
  SET_SUBTITLE: 'SET_SUBTITLE',
  /* eslint-enable @typescript-eslint/naming-convention */
};

export const appStoreGlobalActions = {
  setUserAction: createAction(
    appStoreGlobalLabels.SET_USER,
    props<{ user?: SecurityUser }>()
  ),

  setCollaboratorAction: createAction(
    appStoreGlobalLabels.SET_COLLABORATOR,
    props<{ collaborator?: CollaboratorFull }>()
  ),

  setCompanyAction: createAction(
    appStoreGlobalLabels.SET_COMPANY,
    props<{ company?: Company }>()
  ),

  setIsLoadingAction: createAction(
    appStoreGlobalLabels.SET_IS_LOADING,
    props<{ isLoading: boolean }>()
  ),

  setIsSavingAction: createAction(
    appStoreGlobalLabels.SET_IS_SAVING,
    props<{ isSaving: boolean }>()
  ),

  setScreenIdAction: createAction(
    appStoreGlobalLabels.SET_SCREEN_ID,
    props<{ screenId: string }>()
  ),

  setTitleAction: createAction(
    appStoreGlobalLabels.SET_TITLE,
    props<{ title: string }>()
  ),

  setSubtitleAction: createAction(
    appStoreGlobalLabels.SET_SUBTITLE,
    props<{ subtitle: string }>()
  ),

  setScreenSizeAction: createAction(
    'SET_SCREEN_SIZE',
    props<{
      isWeb: boolean;
      isSmall: boolean;
      isPortrait: boolean;
      isMedium: boolean;
      isLarge: boolean;
    }>()
  ),

  setInnerHeightAction: createAction(
    'SET_INNER_HEIGHT',
    props<{ innerHeight: number }>()
  ),

  setInnerWidthAction: createAction(
    'SET_INNER_WIDTH',
    props<{ innerWidth: number }>()
  ),

  setConnectedAction: createAction(
    'SET_CONNECTED',
    props<{ connected: boolean }>()
  ),

  setAppointmentsToSyncAction: createAction(
    'SET_APPOINTMENTS_TO_SYNC',
    props<{ appointmentsToSync: number }>()
  ),

  setOrdersToSyncAction: createAction(
    'SET_ORDERS_TO_SYNC',
    props<{ ordersToSync: number }>()
  ),

  setCustomersToSyncAction: createAction(
    'SET_CUSTOMERS_TO_SYNC',
    props<{ customersToSync: number }>()
  ),

  setNotificationCounterAction: createAction(
    'GLOBAL_SET_NOTIFICATION_COUNTER',
    props<{ notificationCounter: number }>()
  ),

  decreaseNotificationCounterAction: createAction(
    'GLOBAL_DECREASE_NOTIFICATION_COUNTER',
    props<any>()
  ),

  increaseNotificationCounterAction: createAction(
    'GLOBAL_INCREASE_NOTIFICATION_COUNTER',
    props<any>()
  ),

  setNotificationCounterDtoAction: createAction(
    'GLOBAL_SET_NOTIFICATION_COUNTER_DTO',
    props<{ notificationCounterDto: NotificationCounterDTO }>()
  ),

  setNotificationCounterDtoTotalAction: createAction(
    'GLOBAL_SET_NOTIFICATION_COUNTER_DTO_TOTAL',
    props<{ value: number }>()
  ),

  setNotificationCounterDtoStarredAction: createAction(
    'GLOBAL_SET_NOTIFICATION_COUNTER_DTO_STARRED',
    props<{ value: number }>()
  ),

  setNotificationCounterDtoImportantAction: createAction(
    'GLOBAL_SET_NOTIFICATION_COUNTER_DTO_IMPORTANT',
    props<{ value: number }>()
  ),

  setNotificationCounterDtoDeletedAction: createAction(
    'GLOBAL_SET_NOTIFICATION_COUNTER_DTO_DELETED',
    props<{ value: number }>()
  ),
};
