import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Dropdown, DropdownChangeEvent } from 'primeng/dropdown';
import { SelectButtonChangeEvent } from 'primeng/selectbutton';
import { BaseFiltersComponent } from 'src/app/core/base/base-filters.component';
import { P360DropdownItem } from 'src/app/models/common';
import { appStoreFiltersActions } from 'src/app/store/filters/store.filters.action';
import { AppStorageService } from 'src/app/core/utils/app.storage.service';

@Component({
  selector: '[app-filters-rx]',
  templateUrl: './app.filters.rx.component.html',
})
export class AppFiltersRxComponent
  extends BaseFiltersComponent
  implements OnInit
{
  @ViewChild('measureFilter') measureFilter?: Dropdown;

  measureOptions: P360DropdownItem<string>[] = [];
  measureSelected?: string;

  constructor(
    override store: Store<any>,
    override storage: AppStorageService,
    override renderer: Renderer2,
    override translate: TranslateService
  ) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.store.select('filters', 'rxMeasureList').subscribe((rxMeasureList) => {
      this.measureOptions = rxMeasureList;
    });

    this.store
      .select('filters', 'rxMeasureOption')
      .subscribe((rxMeasureOption) => {
        this.measureSelected = rxMeasureOption.value;
      });
  }

  onMeasureChange = ({ value }: SelectButtonChangeEvent) => {
    const filterOption = this.measureOptions.find(
      (item) => item.value === value
    );

    if (filterOption) {
      this.store.dispatch(
        appStoreFiltersActions.setRxMeasureOptionAction({
          rxMeasureOption: filterOption,
        })
      );
    }
  };

  override customOnLineChange = ({ value }: DropdownChangeEvent) => {
    const filterOption = this.lineOptions.find(
      (item) => item.id === value
    )?.value;

    this.store.dispatch(
      appStoreFiltersActions.setRxMeasureTypeOptionAction({
        rxType: 'LINEA',
        rxLabel: filterOption?.name,
        rxValue: filterOption?.code,
      })
    );
  };

  override customOnCollaboratorChange = ({ value }: DropdownChangeEvent) => {
    const filterOption = this.collaboratorOptions.find(
      (item) => item.id === value
    )?.value;

    this.store.dispatch(
      appStoreFiltersActions.setRxMeasureTypeOptionAction({
        rxType: 'REPRESENTANTE',
        rxLabel: filterOption?.name,
        rxValue: `${filterOption?.code}`,
      })
    );
  };

  override customClearFilters = () => {
    this.store.dispatch(appStoreFiltersActions.clearRxMeasureFilterAction());
    this.store.dispatch(
      appStoreFiltersActions.clearRxMeasureTypeFilterAction()
    );
  };
}
