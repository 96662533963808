import { Injectable } from '@angular/core';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Platform } from '@ionic/angular';

@Injectable()
export class HandleAppVersionService extends AppVersion {
  constructor(private platform: Platform) {
    super();
  }

  override getAppName(): Promise<string> {
    if (this.platform.is('android') || this.platform.is('ios')) {
      return super.getAppName();
    }

    return new Promise((resolve, _) => {
      resolve('P360° Mobile - Browser');
    });
  }

  override getPackageName(): Promise<string> {
    if (this.platform.is('android') || this.platform.is('ios')) {
      return super.getPackageName();
    }

    return new Promise((resolve, _) => {
      resolve('ec.com.bisigma.com');
    });
  }

  override getVersionCode(): Promise<string | number> {
    if (this.platform.is('android') || this.platform.is('ios')) {
      return super.getVersionCode();
    }

    return new Promise((resolve, _) => {
      resolve('w');
    });
  }

  override getVersionNumber(): Promise<string> {
    if (this.platform.is('android') || this.platform.is('ios')) {
      return super.getVersionNumber();
    }

    return new Promise((resolve, _) => {
      resolve('x.y.z');
    });
  }
}
