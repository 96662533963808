import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseService } from 'src/app/core/base/base.service';
import { AppMessageService } from 'src/app/core/services/app-message.service';
import { RestService } from 'src/app/core/services/rest.service';
import { AppStorageService } from 'src/app/core/utils/app.storage.service';

@Injectable({
  providedIn: 'root',
})
export class SharedService extends BaseService {
  constructor(
    override rest: RestService,
    override messageService: AppMessageService,
    override storage: AppStorageService,
    override http: HttpClient,
    override store: Store<any>
  ) {
    super();
  }
}
