import {
  AfterContentInit,
  Component,
  OnDestroy,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ChildrenOutletContexts, NavigationEnd, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Subscription, filter } from 'rxjs';
import { slideInAnimation } from 'src/app/core/utils/animations';
import { BaseComponent } from 'src/app/core/base/base.component';
import { appStoreGlobalActions } from 'src/app/store/global/store.global.action';
import { TabCloseEvent } from './api/tabcloseevent';
import { MenuService } from './app.menu.service';
import { AppSidebarComponent } from './app.sidebar.component';
import { AppTopBarComponent } from './app.topbar.component';
import { LayoutService } from './service/app.layout.service';

@Component({
  selector: 'app-layout',
  templateUrl: './app.layout.component.html',
  styleUrls: ['./app.layout.component.scss'],
  animations: [slideInAnimation],
})
export class AppLayoutComponent
  extends BaseComponent
  implements OnDestroy, AfterContentInit
{
  overlayMenuOpenSubscription: Subscription;

  tabOpenSubscription: Subscription;

  tabCloseSubscription: Subscription;

  menuOutsideClickListener: any;

  menuScrollListener: any;

  @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;

  @ViewChild(AppTopBarComponent) appTopbar!: AppTopBarComponent;

  constructor(
    private menuService: MenuService,
    public layoutService: LayoutService,
    public renderer: Renderer2,
    public router: Router,
    private contexts: ChildrenOutletContexts,
    override store: Store<any>,
    protected platform: Platform
  ) {
    super();
    this.overlayMenuOpenSubscription =
      this.layoutService.overlayOpen$.subscribe(() => {
        if (!this.menuOutsideClickListener) {
          this.menuOutsideClickListener = this.renderer.listen(
            'document',
            'click',
            (event) => {
              const isOutsideClicked = !(
                this.appSidebar.el.nativeElement.isSameNode(event.target) ||
                this.appSidebar.el.nativeElement.contains(event.target) ||
                this.appTopbar.menuButton.nativeElement.isSameNode(
                  event.target
                ) ||
                this.appTopbar.menuButton.nativeElement.contains(event.target)
              );
              if (isOutsideClicked) {
                this.hideMenu();
              }
            }
          );
        }

        if (!this.menuScrollListener) {
          this.menuScrollListener = this.renderer.listen(
            this.appSidebar.menuContainer.nativeElement,
            'scroll',
            (event) => {
              if (this.layoutService.isDesktop()) {
                this.hideMenu();
              }
            }
          );
        }

        // Disabled from the template
        // if (this.layoutService.state.staticMenuMobileActive) {
        //   this.blockBodyScroll();
        // }
      });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.hideMenu();
      });

    this.tabOpenSubscription = this.layoutService.tabOpen$.subscribe((tab) => {
      this.router.navigate(tab.routerLink);
      this.layoutService.openTab(tab);
    });

    this.tabCloseSubscription = this.layoutService.tabClose$.subscribe(
      (event: TabCloseEvent) => {
        if (
          this.router.isActive(event.tab.routerLink[0], {
            paths: 'subset',
            queryParams: 'subset',
            fragment: 'ignored',
            matrixParams: 'ignored',
          })
        ) {
          const tabs = this.layoutService.tabs;

          if (tabs.length > 1) {
            if (event.index === tabs.length - 1)
              this.router.navigate(tabs[tabs.length - 2].routerLink);
            else this.router.navigate(tabs[event.index + 1].routerLink);
          } else {
            this.router.navigate(['/']);
          }
        }

        this.layoutService.closeTab(event.index);
      }
    );
  }

  ngOnDestroy() {
    if (this.overlayMenuOpenSubscription) {
      this.overlayMenuOpenSubscription.unsubscribe();
    }

    if (this.menuOutsideClickListener) {
      this.menuOutsideClickListener();
    }

    if (this.tabOpenSubscription) {
      this.tabOpenSubscription.unsubscribe();
    }

    if (this.tabCloseSubscription) {
      this.tabCloseSubscription.unsubscribe();
    }
  }

  ngAfterContentInit(): void {
    const innerHelper: HTMLElement = document.querySelector(
      '.layout-content-inner'
    );
    const areaHelper: HTMLElement =
      document.querySelector('.main-area__helper');
    const footerHelper: HTMLElement = document.querySelector('.layout-footer');

    setTimeout(() => {
      const innerWidth = areaHelper?.clientWidth ?? 0;
      const innerHeight =
        (innerHelper?.scrollHeight ?? this.platform.height()) -
        (areaHelper?.offsetTop ?? 0) -
        (footerHelper?.scrollHeight ?? 0);

      this.store.dispatch(
        appStoreGlobalActions.setInnerHeightAction({
          innerHeight,
        })
      );

      this.store.dispatch(
        appStoreGlobalActions.setInnerWidthAction({
          innerWidth,
        })
      );
    }, 1000);
  }

  blockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  }

  unblockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          '(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)',
          'gi'
        ),
        ' '
      );
    }
  }

  hideMenu() {
    this.layoutService.state.overlayMenuActive = false;
    this.layoutService.state.staticMenuMobileActive = false;
    this.layoutService.state.menuHoverActive = false;
    this.menuService.reset();
    if (this.menuOutsideClickListener) {
      this.menuOutsideClickListener();
      this.menuOutsideClickListener = null;
    }

    if (this.menuScrollListener) {
      this.menuScrollListener();
      this.menuScrollListener = null;
    }

    this.unblockBodyScroll();
  }

  get containerClass() {
    return {
      'layout-overlay-active': this.layoutService.state.overlayMenuActive,
      'layout-mobile-active': this.layoutService.state.staticMenuMobileActive,
      'p-input-filled': this.layoutService.config().inputStyle === 'filled',
      'p-ripple-disabled': !this.layoutService.config().ripple,
      'is-portrait': this.isPortrait,
    };
  }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.[
      'animation'
    ];
  }
}
