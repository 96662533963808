import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Component({
  selector: '[app-customer-actions]',
  template: `
    <div class="flex justify-content-center gap-4">
      <p-button
        icon="fa-regular fa-hospital text-3xl"
        styleClass="p-5"
        severity="primary"
        [outlined]="true"
        [rounded]="true"
        [text]="true"
        (onClick)="goTo(0)"
        *ngIf="showAppointment"
      ></p-button>

      <p-button
        icon="fa-solid fa-cart-plus text-3xl"
        styleClass="p-5"
        severity="primary"
        [outlined]="true"
        [rounded]="true"
        [text]="true"
        (onClick)="goTo(1)"
        *ngIf="showOrder"
      ></p-button>

      <p-button
        icon="fa-solid fa-eye text-3xl"
        styleClass="p-5"
        severity="primary"
        [outlined]="true"
        [rounded]="true"
        [text]="true"
        (onClick)="goTo(2)"
        [disabled]="isNewCustomer"
        *ngIf="showView && showCustomerDetail"
      ></p-button>

      <ng-content></ng-content>
    </div>
  `,
})
export class AppCustomerActionsComponent implements OnInit {
  @Input() isNewCustomer = false;
  @Input() code = '';
  @Input() type = '';
  @Input() mapId?: number;
  @Input() prevRoute?: string;
  @Input() showView = true;
  @Input() hideMapId?: boolean;

  showAppointment = false;
  showOrder = false;
  showCustomerDetail = false;

  constructor(protected store: Store<any>, protected router: Router) {}

  ngOnInit(): void {
    this.store
      .select('screen', 'claims')
      .subscribe((claims?: { [key: string]: string[] }) => {
        this.showAppointment =
          claims?.['crm.map']?.includes('addAppointment') ?? false;
        this.showOrder = claims?.['crm.map']?.includes('addOrder') ?? false;
        this.showCustomerDetail =
          claims?.['crm.map']?.includes('seeDetails') ?? false;
      });
  }

  goTo = (op: number) => {
    let route = '';
    switch (op) {
      case 0:
        route = 'visita';
        break;
      case 1:
        route = 'pedido';
        break;
      case 2:
        route = 'detalle-cliente';
        break;
    }

    const queryParams: { [key: string]: string | number } = {
      codigo: this.code,
      tipo: this.type,
    };

    if (this.mapId && !this.hideMapId) {
      queryParams['id-rutero'] = this.mapId;
    }

    if (this.prevRoute) {
      queryParams['regresar'] = this.prevRoute;
    }

    this.router?.navigate(['/p360', route], {
      queryParams,
    });
  };
}
