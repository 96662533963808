import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { SecurityOption } from 'src/app/models/security/security-option';
import { LayoutService } from './service/app.layout.service';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
  model: MenuItem[] = [];

  constructor(
    public layoutService: LayoutService,
    protected store: Store<any>
  ) {}

  ngOnInit() {
    this.store
      .select('global', 'user', 'options')
      .subscribe((options: SecurityOption[]) => {
        options.sort((a, b) => a.order - b.order);
        this.model = options.map(this.mapOptions);
      });
  }

  private mapOptions = (item: SecurityOption) => {
    const res = {
      label: item.name,
      routerLink: item.route,
      icon: item.icon,
      image: item.image,
      iconProvider: item.iconProvider,
      status: item.status,
      visible: item.visible,
      items: item.children
        ?.filter((child) => child.status && child.visible)
        .map(this.mapOptions),
    };
    if (res.items?.length === 0) {
      delete res.items;
    }
    return res;
  };
}
