import { Component, ElementRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseComponent } from 'src/app/core/base/base.component';
import { LayoutService } from './service/app.layout.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './app.sidebar.component.html',
  styleUrls: ['./app.sidebar.component.scss'],
})
export class AppSidebarComponent extends BaseComponent {
  @ViewChild('menuContainer')
  menuContainer!: ElementRef;

  constructor(
    override store: Store<any>,
    public layoutService: LayoutService,
    public el: ElementRef
  ) {
    super();
  }
}
