import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-star-ranking',
  template: `<span class="fa-regular stars-rating"
    ><span class="fa-solid star" [style.width.%]="ranking * 100"></span
  ></span>`,
  styleUrls: ['./star-ranking.scss'],
})
export class AppStarRankingComponent {
  @Input() ranking = 0;
}
