export class DateUtils {
  public static addingDays = (date: any, days: number): Date => {
    const result = new Date(date);
    const daysInMillis: number = days * 86400000;
    return new Date(result.getTime() + daysInMillis);
  };

  public static checkDate = (date1: any, date2: any) => {
    date1 = new Date(date1);
    date1.setHours(0, 0, 0, 0);
    date2 = new Date(date2);
    date2.setHours(0, 0, 0, 0);
    return date1.getTime() === date2.getTime();
  };

  public static checkCurrentMat = (date: any) => {
    date = DateUtils.addMonth(new Date(date), 0);
    const range1 = DateUtils.substractMonth(new Date(), 11);
    const range2 = DateUtils.substractMonth(new Date(), 0);
    return (
      date.getTime() >= range1.getTime() && date.getTime() <= range2.getTime()
    );
  };

  public static checkPreviousMat = (date: any) => {
    date = DateUtils.addMonth(new Date(date), 0);
    const range1 = DateUtils.substractMonth(new Date(), 21);
    const range2 = DateUtils.substractMonth(new Date(), 12);
    return (
      date.getTime() >= range1.getTime() && date.getTime() <= range2.getTime()
    );
  };

  public static checkCurrentQuarter = (date: Date) => {
    date = DateUtils.addMonth(new Date(date), 0);
    const range1 = DateUtils.substractMonth(new Date(), 2);
    const range2 = DateUtils.substractMonth(new Date(), 0);
    return (
      date.getTime() >= range1.getTime() && date.getTime() <= range2.getTime()
    );
  };

  public static checkPreviousQuarter = (date: Date) => {
    date = DateUtils.addMonth(new Date(date), 0);
    const range1 = DateUtils.substractMonth(new Date(), 6);
    const range2 = DateUtils.substractMonth(new Date(), 3);
    return (
      date.getTime() >= range1.getTime() && date.getTime() <= range2.getTime()
    );
  };

  public static checkCurrentMonth = (date: any) => {
    date = DateUtils.addMonth(new Date(date), 0);
    const current = DateUtils.addMonth(new Date(), 0);
    return current.getTime() === date.getTime();
  };

  public static checkPreviousMonth = (date: any) => {
    date = DateUtils.addMonth(new Date(date), 0);
    const previous = DateUtils.substractMonth(new Date(), 1);
    return previous.getTime() === date.getTime();
  };

  public static addYear = (date: Date, quantity: number) =>
    DateUtils.addSubYear(date, quantity, true);

  public static substractYear = (date: Date, quantity: number) =>
    DateUtils.addSubYear(date, quantity, false);

  public static addMonth = (date: Date, quantity: number) =>
    DateUtils.addSubMonth(date, quantity, true);

  public static substractMonth = (date: Date, quantity: number) =>
    DateUtils.addSubMonth(date, quantity, false);

  public static addDate = (date: Date, quantity: number) =>
    DateUtils.addSubDate(date, quantity, true);

  public static substractDate = (date: Date, quantity: number) =>
    DateUtils.addSubDate(date, quantity, false);

  public static getMonths = (date: Date, quantity: number) => {
    const result: any = {};
    for (let i = 1; i <= quantity; i++) {
      result[`m${i}`] = DateUtils.substractMonth(date, 1);
    }
    return result;
  };

  public static firstDayCurrentMonth = (): Date => {
    const result = new Date();
    result.setDate(1);
    return result;
  };

  public static firstDayFor = (date: Date): Date => {
    const result = new Date(date);
    result.setDate(1);
    return result;
  };

  private static addSubYear = (
    date: Date,
    quantity: number,
    isAdd: boolean
  ) => {
    if (isAdd) {
      date.setFullYear(date.getFullYear() + quantity);
    } else {
      date.setFullYear(date.getFullYear() - quantity);
    }
    date.setHours(0, 0, 0, 0);
    date.setDate(1);
    return new Date(date);
  };

  private static addSubMonth = (
    date: Date,
    quantity: number,
    isAdd: boolean
  ) => {
    if (isAdd) {
      date.setMonth(date.getMonth() + quantity);
    } else {
      date.setMonth(date.getMonth() - quantity);
    }
    date.setHours(0, 0, 0, 0);
    date.setDate(1);
    return new Date(date);
  };

  private static addSubDate = (
    date: Date,
    quantity: number,
    isAdd: boolean
  ) => {
    if (isAdd) {
      date.setDate(date.getDate() + quantity);
    } else {
      date.setDate(date.getDate() - quantity);
    }
    date.setHours(0, 0, 0, 0);
    return new Date(date);
  };
}
