import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { finalize, Observable, tap } from 'rxjs';
import { BaseService } from 'src/app/core/base/base.service';
import { AppMessageService } from 'src/app/core/services/app-message.service';
import { RestService } from 'src/app/core/services/rest.service';
import { AppConstants } from 'src/app/core/utils/app.const';
import { AppStorageService } from 'src/app/core/utils/app.storage.service';
import { AppStorage } from './core/utils/app.storage';
import { LayoutService } from './layout/service/app.layout.service';
import { Client } from './models/client';
import { Collaborator } from './models/collaborator';
import { P360DropdownItem } from './models/common';
import { Dispatcher } from './models/dispatcher';
import { GlobalCatalog } from './models/global-catalog';
import { Line } from './models/line';
import { ProductItem } from './models/product-item';
import { Segment } from './models/segment';
import { appStoreFiltersActions } from './store/filters/store.filters.action';

@Injectable({
  providedIn: 'root',
})
export class AppService extends BaseService {
  private _loadInfoCounter = 0;

  constructor(
    override rest: RestService,
    override messageService: AppMessageService,
    override storage: AppStorageService,
    override store: Store<any>,
    override layoutService: LayoutService
  ) {
    super();
  }

  private getGen0000 = (
    params: any
  ): Observable<{ results?: GlobalCatalog[] }> =>
    this.restGet(AppConstants.REST_ENDPOINTS.GEN_0000, params);

  private getGen0002 = (params: any): Observable<{ results?: ProductItem[] }> =>
    this.restGet(AppConstants.REST_ENDPOINTS.GEN_0002, params).pipe(
      tap({
        next: () => {
          this.messageService.addSuccess(
            'MESSAGE.200_PREFIX#MESSAGE.200_SYNC_GEN_0002',
            {},
            true
          );
        },
      })
    );

  private getGen0030 = (params: any): Observable<{ results?: Dispatcher[] }> =>
    this.restGet(AppConstants.REST_ENDPOINTS.GEN_0030, params);

  private getGen0001 = (params: any): Observable<{ results?: Client[] }> =>
    this.restGet(AppConstants.REST_ENDPOINTS.GEN_0001, params).pipe(
      tap({
        next: () => {
          this.messageService.addSuccess(
            'MESSAGE.200_PREFIX#MESSAGE.200_SYNC_GEN_0001',
            {},
            true
          );
        },
      })
    );

  private getGen0019 = (params: any): Observable<{ results?: Client[] }> =>
    this.restGet(AppConstants.REST_ENDPOINTS.GEN_0019, params).pipe(
      tap({
        next: () => {
          this.messageService.addSuccess(
            'MESSAGE.200_PREFIX#MESSAGE.200_SYNC_GEN_0019',
            {},
            true
          );
        },
      })
    );

  private getGen0021 = (params: any): Observable<{ results?: Line[] }> =>
    this.restGet(AppConstants.REST_ENDPOINTS.GEN_0021, params);

  private getGen0022 = (params: any): Observable<{ results?: Segment[] }> =>
    this.restGet(AppConstants.REST_ENDPOINTS.GEN_0022, params);

  private getCollaborators = (
    params: any
  ): Observable<{ results?: Collaborator[] }> =>
    this.restGet(AppConstants.REST_ENDPOINTS.SALESFORCE_TREE, params);

  handleRefreshInformation = (agentCode: string, isRefresh = false) => {
    this._loadInfoCounter = 1;
    this.whoAmI().subscribe(() => {
      this.messageService.addSuccess(
        'MESSAGE.200_PREFIX#MESSAGE.200_SYNC_WHO_AM_I',
        {},
        true
      );
    });
    this.loadGlobalCatalogs(agentCode, isRefresh);
    this.loadItems(agentCode, isRefresh);
    this.loadDispatchers(agentCode, isRefresh);
    this.loadCollaborators(agentCode, isRefresh);
    this.loadClients(agentCode, isRefresh);
    this.loadClientsForFilters(agentCode, isRefresh);
    this.loadLines(agentCode, isRefresh);
    this.loadSegment(agentCode, isRefresh);
  };

  loadGlobalCatalogs = (document: string, isRefresh = false) => {
    this.storage.get(AppStorage.GLOBAL_CATALOGS).then((globalCatalogs) => {
      if (isRefresh || globalCatalogs == null) {
        this.getGen0000({ document })
          .pipe(
            finalize(() => {
              this.handleLoadInfoCounter();
            })
          )
          .subscribe({
            next: ({ results: globalCatalogList }) => {
              this.storage.remove(AppStorage.GLOBAL_CATALOGS);
              if (globalCatalogList.length > 0) {
                this.storage.set(AppStorage.GLOBAL_CATALOGS, globalCatalogList);
                this.store.dispatch(
                  appStoreFiltersActions.setGlobalCatalogListAction({
                    globalCatalogList,
                  })
                );
              }
            },
          });
      } else {
        this.handleLoadInfoCounter();
        this.store.dispatch(
          appStoreFiltersActions.setGlobalCatalogListAction({
            globalCatalogList: globalCatalogs,
          })
        );
      }
    });
  };

  loadItems = (document: string, isRefresh = false) => {
    this.storage
      .get<ProductItem[]>(AppStorage.PRODUCT_ITEMS)
      .then((productItems) => {
        if (isRefresh || productItems == null) {
          this.getGen0002({ document })
            .pipe(
              finalize(() => {
                this.handleLoadInfoCounter();
              })
            )
            .subscribe({
              next: ({ results: productItemList }) => {
                this.storage.remove(AppStorage.PRODUCT_ITEMS);
                if (productItemList.length > 0) {
                  this.storage.set(AppStorage.PRODUCT_ITEMS, productItemList);
                  this.store.dispatch(
                    appStoreFiltersActions.setGlobalProductItemListAction({
                      productItemList,
                    })
                  );
                }
              },
            });
        } else {
          this.handleLoadInfoCounter();
          this.store.dispatch(
            appStoreFiltersActions.setGlobalProductItemListAction({
              productItemList: productItems,
            })
          );
        }
      });
  };

  loadDispatchers = (document: string, isRefresh = false) => {
    this.storage
      .get<Dispatcher[]>(AppStorage.DISPATCHERS)
      .then((dispatchers) => {
        if (isRefresh || dispatchers == null) {
          this.getGen0030({ document })
            .pipe(
              finalize(() => {
                this.handleLoadInfoCounter();
              })
            )
            .subscribe({
              next: ({ results: dispatcherList }) => {
                this.storage.remove(AppStorage.DISPATCHERS);
                if (dispatcherList.length > 0) {
                  this.storage.set(AppStorage.DISPATCHERS, dispatcherList);
                  this.store.dispatch(
                    appStoreFiltersActions.setGlobalDispatcherListAction({
                      dispatcherList,
                    })
                  );
                }
              },
            });
        } else {
          this.handleLoadInfoCounter();
          this.store.dispatch(
            appStoreFiltersActions.setGlobalDispatcherListAction({
              dispatcherList: dispatchers,
            })
          );
        }
      });
  };

  loadCollaborators = (document: string, isRefresh = false) => {
    this.storage
      .get<P360DropdownItem<Collaborator>[]>(AppStorage.COLLABORATORS)
      .then((collaborators) => {
        if (isRefresh || collaborators == null) {
          this.getCollaborators({ document })
            .pipe(
              finalize(() => {
                this.handleLoadInfoCounter();
              })
            )
            .subscribe({
              next: (data) => {
                const collaborators =
                  data.results?.map((item: any) => ({
                    ...item,
                    isNew: false,
                  })) ?? [];

                const firstItem = {
                  id: 0,
                  label: 'ALL',
                  value: {
                    id: 0,
                    name: 'ALL',
                    code: 'TODOS',
                  },
                };
                const collaboratorFilterList: P360DropdownItem<Collaborator>[] =
                  [
                    firstItem,
                    ...collaborators.map((item, idx) => ({
                      id: idx + 1,
                      label: item.name,
                      value: { id: idx + 1, ...item },
                    })),
                  ];

                this.storage.remove(AppStorage.COLLABORATORS);
                if (collaborators.length > 0) {
                  this.storage.set(
                    AppStorage.COLLABORATORS,
                    collaboratorFilterList
                  );
                  this.store.dispatch(
                    appStoreFiltersActions.setCollaboratorListAction({
                      collaboratorFilterList,
                    })
                  );
                } else {
                  this.store.dispatch(
                    appStoreFiltersActions.setCollaboratorListAction({
                      collaboratorFilterList: [firstItem],
                    })
                  );
                }
              },
            });
        } else {
          this.handleLoadInfoCounter();
          this.store.dispatch(
            appStoreFiltersActions.setCollaboratorListAction({
              collaboratorFilterList: collaborators,
            })
          );
        }
      });
  };

  loadClients = (document: string, isRefresh = false) => {
    this.storage
      .get<P360DropdownItem<Client>[]>(AppStorage.CLIENTS)
      .then((clients) => {
        if (isRefresh || clients == null) {
          this.getGen0001({ document })
            .pipe(
              finalize(() => {
                this.handleLoadInfoCounter();
              })
            )
            .subscribe({
              next: (data) => {
                const clients =
                  data.results?.map((item: any) => ({
                    ...item,
                    isNew: false,
                  })) ?? [];

                const clientFilterList: P360DropdownItem<Client>[] = [
                  {
                    id: 0,
                    label: 'ALL',
                    value: {
                      id: 0,
                      name: 'ALL',
                      code: 'TODOS',
                    },
                  },
                  ...clients.map((item, idx) => ({
                    id: idx + 1,
                    label: item.name,
                    value: { id: idx + 1, ...item },
                  })),
                ];

                this.storage.remove(AppStorage.CLIENTS);
                if (clients.length > 0) {
                  this.storage.set(AppStorage.CLIENTS, clientFilterList);

                  this.store.dispatch(
                    appStoreFiltersActions.setClientListAction({
                      clientFilterList,
                    })
                  );
                }
              },
            });
        } else {
          this.handleLoadInfoCounter();
          this.store.dispatch(
            appStoreFiltersActions.setClientListAction({
              clientFilterList: clients,
            })
          );
        }
      });
  };

  loadClientsForFilters = (document: string, isRefresh = false) => {
    this.storage
      .get<P360DropdownItem<Client>[]>(AppStorage.CLIENTS_FOR_FILTERS)
      .then((clients) => {
        if (isRefresh || clients == null) {
          this.getGen0019({ document })
            .pipe(
              finalize(() => {
                this.handleLoadInfoCounter();
              })
            )
            .subscribe({
              next: (data) => {
                const clientForFiltersFilterList =
                  data.results?.map((item, idx) => ({
                    id: idx + 1,
                    label: item.name,
                    value: { id: idx + 1, ...item },
                  })) ?? [];

                this.storage.remove(AppStorage.CLIENTS_FOR_FILTERS);
                if (clientForFiltersFilterList.length > 0) {
                  this.storage.set(
                    AppStorage.CLIENTS_FOR_FILTERS,
                    clientForFiltersFilterList
                  );

                  this.store.dispatch(
                    appStoreFiltersActions.setClientForFiltersListAction({
                      clientForFiltersFilterList,
                    })
                  );
                }
              },
            });
        } else {
          this.handleLoadInfoCounter();
          this.store.dispatch(
            appStoreFiltersActions.setClientForFiltersListAction({
              clientForFiltersFilterList: clients,
            })
          );
        }
      });
  };

  loadLines = (document: string, isRefresh = false) => {
    this.storage
      .get<P360DropdownItem<Line>[]>(AppStorage.LINES)
      .then((lines) => {
        if (isRefresh || lines == null) {
          this.getGen0021({ document })
            .pipe(
              finalize(() => {
                this.handleLoadInfoCounter();
              })
            )
            .subscribe({
              next: (data) => {
                const lineFilterList: P360DropdownItem<any>[] =
                  data.results?.map((item: any, idx: number) => ({
                    id: idx + 1,
                    label: item.name,
                    value: { id: idx + 1, ...item },
                  })) ?? [];

                this.storage.remove(AppStorage.LINES);
                if (lineFilterList.length > 0) {
                  this.storage.set(AppStorage.LINES, lineFilterList);

                  this.store.dispatch(
                    appStoreFiltersActions.setLineListAction({
                      lineFilterList,
                    })
                  );
                } else {
                  this.store.dispatch(
                    appStoreFiltersActions.setLineListAction({
                      lineFilterList: [
                        {
                          id: -1,
                          label: 'ALL',
                          value: {
                            id: -1,
                            name: 'ALL',
                            code: 'TODOS',
                          },
                        },
                      ],
                    })
                  );
                }
              },
            });
        } else {
          this.handleLoadInfoCounter();
          this.store.dispatch(
            appStoreFiltersActions.setLineListAction({
              lineFilterList: lines,
            })
          );
        }
      });
  };

  loadSegment = (document: string, isRefresh = false) => {
    this.storage
      .get<P360DropdownItem<Segment>[]>(AppStorage.SEGMENTS)
      .then((segments) => {
        if (isRefresh || segments == null) {
          this.getGen0022({ document })
            .pipe(
              finalize(() => {
                this.handleLoadInfoCounter();
              })
            )
            .subscribe({
              next: (data) => {
                const segmentFilterList: P360DropdownItem<any>[] =
                  data.results?.map((item: any, idx: number) => ({
                    id: idx + 1,
                    label: item.name,
                    value: { id: idx + 1, ...item },
                  })) ?? [];
                this.storage.remove(AppStorage.SEGMENTS);
                if (segmentFilterList.length > 0) {
                  this.storage.set(AppStorage.SEGMENTS, segmentFilterList);
                  this.store.dispatch(
                    appStoreFiltersActions.setSegmentListAction({
                      segmentFilterList,
                    })
                  );
                } else {
                  this.store.dispatch(
                    appStoreFiltersActions.setSegmentListAction({
                      segmentFilterList: [
                        {
                          id: -1,
                          label: 'ALL',
                          value: {
                            id: -1,
                            name: 'ALL',
                            code: 'TODOS',
                          },
                        },
                      ],
                    })
                  );
                }
              },
            });
        } else {
          this.handleLoadInfoCounter();
          this.store.dispatch(
            appStoreFiltersActions.setSegmentListAction({
              segmentFilterList: segments,
            })
          );
        }
      });
  };

  loadOfflineData = (document: string) => {
    this._loadInfoCounter = 1;
    this.loadGlobalCatalogs(document);
    this.loadItems(document);
    this.loadDispatchers(document);
    this.loadCollaborators(document);
    this.loadClients(document);
    this.loadClientsForFilters(document);
    this.loadLines(document);
    this.loadSegment(document);
  };

  changePassword = (username: string, password: string) => {
    const authorization = btoa(`${password}`);
    return this.rest
      .patch(
        `${AppConstants.SECURITY_HOST}/security/user/pass/{username}`,
        authorization,
        { username }
      )
      .pipe(
        tap({
          next: (res) => {
            this.whoAmI().subscribe();
          },
        })
      );
  };

  private handleLoadInfoCounter = () => {
    this._loadInfoCounter++;
    if (this._loadInfoCounter > 8) {
      this._loadInfoCounter = 0;
    }
  };

  get loadInfoCounter() {
    return this._loadInfoCounter !== 0;
  }
}
