<button class="layout-config-button p-link" type="button" (click)="onConfigButtonClick()" [ngClass]="{ hide: menuVisible, 'layout-config-button--landscape': isSmall && !isPortrait }">
  <i class="fa-solid fa-gears"></i>
</button>

<p-sidebar [(visible)]="visible" position="right" [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'" styleClass="layout-config-sidebar w-18rem" appendTo="body">
  <!-- TODO: Enable language -->
  <!-- <h5>
    {{ "LANGUAGE" | translate }}
  </h5> -->
  <!-- <div class="grid formgrid">
    <div class="col-12 md:col-6">
      <div class="field-radiobutton">
        <p-radioButton name="spanish" value="es" [(ngModel)]="language" id="es"></p-radioButton>
        <label for="es">{{ "ES" | translate }}</label>
      </div>
    </div>
    <div class="col-12 md:col-6">
      <div class="field-radiobutton">
        <p-radioButton name="english" value="en" [(ngModel)]="language" id="en"></p-radioButton>
        <label for="en">{{ "EN" | translate }}</label>
      </div>
    </div>
  </div> -->

  <h5>
    {{ "FORMAT" | translate }}
  </h5>
  <div class="grid formgrid">
    <div class="col-12">
      <div class="field-radiobutton">
        <p-radioButton name="units" value="units" [(ngModel)]="format" (ngModelChange)="handleFormatChange($event)" id="units"></p-radioButton>
        <label for="units">{{ "SETTINGS_UNITS" | translate }}</label>
      </div>
    </div>
    <div class="col-12">
      <div class="field-radiobutton">
        <p-radioButton name="thousands" value="thousands" [(ngModel)]="format" (ngModelChange)="handleFormatChange($event)" id="thousands"></p-radioButton>
        <label for="thousands">{{ "SETTINGS_THOUSANDS" | translate }}</label>
      </div>
    </div>
    <div class="col-12">
      <div class="field-radiobutton">
        <p-radioButton name="millions" value="millions" [(ngModel)]="format" (ngModelChange)="handleFormatChange($event)" id="millions"></p-radioButton>
        <label for="millions">{{ "SETTINGS_MILLIONS" | translate }}</label>
      </div>
    </div>
  </div>

  <h5>
    {{ "PRECISION" | translate }}
  </h5>
  <div class="grid formgrid">
    <div class="col-12">
      <div class="field-radiobutton">
        <p-radioButton validation name="zero" [value]="0" [(ngModel)]="precision" (ngModelChange)="handlePrecisionChange($event)" id="zero"></p-radioButton>
        <label for="zero">{{ "ZERO" | translate }}</label>
      </div>
    </div>
    <div class="col-12">
      <div class="field-radiobutton">
        <p-radioButton validation name="one" [value]="1" [(ngModel)]="precision" (ngModelChange)="handlePrecisionChange($event)" id="one"></p-radioButton>
        <label for="one">{{ "ONE" | translate }}</label>
      </div>
    </div>
    <div class="col-12">
      <div class="field-radiobutton">
        <p-radioButton validation name="two" [value]="2" [(ngModel)]="precision" (ngModelChange)="handlePrecisionChange($event)" id="two"></p-radioButton>
        <label for="two">{{ "TWO" | translate }}</label>
      </div>
    </div>
  </div>

  <h5>
    {{ "SETTINGS_THEME" | translate }}
  </h5>
  <div class="flex flex-wrap gap-3">
    <div *ngFor="let theme of componentThemes">
      <a
        style="cursor: pointer"
        (click)="changeTheme(theme.name)"
        class="inline-flex justify-content-center align-items-center w-2rem h-2rem border-round"
        [ngStyle]="{ 'background-color': theme.lightColor, color: '#ffffff' }"
      >
        <i class="pi pi-check" *ngIf="currentTheme === theme.name"></i>
      </a>
    </div>
  </div>

  <h5>
    {{ "SETTINGS_SCALE" | translate }}
  </h5>
  <div class="flex align-items-center">
    <button icon="pi pi-minus" type="button" pButton (click)="decrementScale()" class="p-button-text p-button-rounded w-2rem h-2rem mr-2" [disabled]="scale === scales[0]"></button>
    <div class="flex gap-3 align-items-center">
      <i class="pi pi-circle-fill text-300" *ngFor="let s of scales" [ngClass]="{ 'text-primary-500': s === scale }"></i>
    </div>
    <button icon="pi pi-plus" type="button" pButton (click)="incrementScale()" class="p-button-text p-button-rounded w-2rem h-2rem ml-2" [disabled]="scale === scales[scales.length - 1]"></button>
  </div>

  <div class="flex flex-column gap-2 mt-4">
    <p-button type="button" iconPos="left" icon="fa-solid fa-rotate" [loading]="isRefreshing" [label]="'REFRESH_INFO' | translate" styleClass="w-full" (onClick)="handleRefreshInfo($event)"></p-button>

    <p-button type="button" iconPos="left" icon="fa-solid fa-trash-can" [label]="'CLEAR_ALL' | translate" [outlined]="true" styleClass="w-full" (onClick)="handleClearAll($event)"></p-button>
  </div>
</p-sidebar>
