import { Injectable } from '@angular/core';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { Platform } from '@ionic/angular';

@Injectable()
export class HandleFirebaseService extends FirebaseX {
  constructor(private platform: Platform) {
    super();
  }

  override getToken(): Promise<null | string> {
    if (this.platform.is('android') || this.platform.is('ios')) {
      return super.getToken();
    }

    return new Promise((resolve, _) => {
      resolve('temporary-unavailable-dev-token');
    });
  }

  override subscribe(topic: string): Promise<any> {
    if (this.platform.is('android') || this.platform.is('ios')) {
      return super.subscribe(topic);
    }

    return new Promise((resolve, _) => {
      resolve(`temporary-unavailable-dev-sub-${topic}`);
    });
  }

  override unsubscribe(topic: string): Promise<any> {
    if (this.platform.is('android') || this.platform.is('ios')) {
      return super.unsubscribe(topic);
    }

    return new Promise((resolve, _) => {
      resolve(`temporary-unavailable-dev-unsub-${topic}`);
    });
  }
}
