import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/services/auth-guard.service';
import { NotAuthGuard } from './core/services/not-auth-guard.service';
import { AppLayoutComponent } from './layout/app.layout.component';

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      { path: '', redirectTo: '/p360', pathMatch: 'full' },
      {
        path: 'p360',
        loadChildren: () =>
          import('./pages/pages.module').then((m) => m.PagesModule),
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'inicio-sesion',
    loadChildren: () =>
      import('./pages/security/login/login.module').then(
        (m) => m.LoginPageModule
      ),
    canActivate: [NotAuthGuard],
  },
  {
    path: 'registro',
    loadChildren: () =>
      import('./pages/security/register/register.module').then(
        (m) => m.RegisterPageModule
      ),
  },
  { path: '**', redirectTo: '/p360/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, NotAuthGuard],
})
export class AppRoutingModule {}
