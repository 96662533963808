import { CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dynamicMetric',
  pure: false,
})
export class DynamicMetricPipe implements PipeTransform {
  constructor(
    protected percentPipe: PercentPipe,
    protected decimalPipe: DecimalPipe,
    protected currencyPipe: CurrencyPipe
  ) {}

  transform(value: number, precision: number, type: string): any {
    if (type.toLowerCase() === 'porcentaje') {
      return this.percentPipe.transform(value, '1.1-1');
    }

    const multiplier = value > 10000 ? 1000 : 1;
    const suffix = value > 10000 ? ' K' : '';

    if (type.toLowerCase() === 'valor') {
      return (
        this.decimalPipe.transform(
          value / multiplier,
          `1.${precision}-${precision}`
        ) + suffix
      );
    }

    if (type.toLowerCase() === 'moneda') {
      return (
        this.currencyPipe.transform(
          value / multiplier,
          '$',
          'symbol',
          `1.${precision}-${precision}`
        ) + suffix
      );
    }

    return value;
  }
}
