import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { DxChartComponent } from 'devextreme-angular';
import { SeriesPoint } from 'devextreme/common/charts';
import { BaseComponent } from 'src/app/core/base/base.component';
import { AppColor } from 'src/app/models/app.colors';
import { PharmaColorsModel } from 'src/app/models/pharma.model';

@Component({
  selector: 'pharma-chart',
  templateUrl: './app-chart.html',
})
export class AppChartComponent extends BaseComponent implements OnInit {
  @ViewChild('pharmaChart') pharmaChart?: DxChartComponent;

  @Input() dataSource: any[] = [];
  @Input() typeFormat?: string;
  @Input() height?: number;
  @Input() rotationAngle = 20;
  @Input() fontSize = '0.85rem';
  @Input() showPlan = true;
  @Input() showSales = true;
  @Input() visibleSalesPrev = true;
  @Input() showSalesPrev = true;
  @Input() showFulfillment = true;
  @Input() isHideOnLegend = true;
  @Input() maxTextLength = 20;
  @Input() symbol?: string;
  @Input() aggregationInterval: any;
  @Input() isMonth = false;

  @Output() legendClick = new EventEmitter<any>();
  @Output() argumentAxisClick = new EventEmitter<any>();
  @Output() contentReady = new EventEmitter<any>();
  @Output() initialized = new EventEmitter<any>();

  appColors?: AppColor;
  axis: any[] = [
    {
      grid: { visible: true },
      label: {
        visible: true,
        format: {
          precision: 0,
        },
      },
      position: 'left',
    },
    {
      color: new PharmaColorsModel().text.fulfillment,
      grid: { visible: true },
      label: {
        visible: true,
        font: { color: new PharmaColorsModel().text.fulfillment },
        format: {
          type: 'percent',
          precision: 0,
        },
      },
      min: 0,
      name: 'fulfillment',
      position: 'right',
    },
  ];

  constructor(
    override store: Store<any>,
    override platform: Platform,
    protected elRef: ElementRef
  ) {
    super();
  }

  override ngOnInit(): void {
    this.store?.select('global', 'isSmall').subscribe((isSmall: boolean) => {
      setTimeout(() => {
        this.axis[0].label.visible = !isSmall;
        this.axis[1].label.visible = !isSmall;
      }, 0);
    });

    this.store?.select('global', 'appColors').subscribe((appColors) => {
      this.appColors = appColors;
    });

    super.ngOnInit();
  }

  onLegendClickEvent = (e: any) => {
    if (!this.isHideOnLegend) {
      this.legendClick.emit(e);
      return;
    }
    const series = e.target;
    if (series.isVisible()) {
      series.hide();
    } else {
      series.show();
    }
  };

  customizeTextEvent = (e: any) => {
    if (this.maxTextLength === 0 || e.valueText.length <= this.maxTextLength) {
      return e.valueText;
    }
    return e.valueText.substring(0, this.maxTextLength).concat('...');
  };

  onArgumentAxisClickEvent = (e: any) => {
    this.argumentAxisClick.emit(e);
  };

  onContentReadyEvent = (e: any) => {
    this.contentReady.emit(e);
  };

  onInitializedEvent = (e: any) => {
    this.initialized.emit(e);
  };

  refreshChart = () => {
    if (this.pharmaChart?.instance) {
      this.pharmaChart.instance.beginUpdate();
      this.pharmaChart.instance.render({
        force: true,
        animate: false,
      });
      this.pharmaChart.instance.endUpdate();
    }
  };

  reloadDatasource = (datasource: any[]) => {
    this.pharmaChart?.instance.beginUpdate();
    this.pharmaChart?.instance.option('dataSource', datasource);
    this.pharmaChart?.instance.endUpdate();
  };

  serieCustomLabel = (arg: any) => {
    if (arg.value === 0) {
      return '';
    }
    return arg.valueText;
  };

  customizePoint = ($ev: any): SeriesPoint => {
    if (!$ev.value || $ev.value === 0) {
      return {
        visible: false,
      };
    }
    return {};
  };

  get dxoFormatType(): string | undefined {
    if (!this.settings.format) {
      return undefined;
    }

    return this.settings.format === 'units'
      ? this.filters.formatTypeSelected
      : this.settings.format;
  }

  get chartHeight(): number {
    return this.isSmall ? this.screenHeight - 50 : this.height;
  }

  get planChart() {
    return this.company.theme.planChart ?? this.appColors.charts.plan;
  }
  get planText() {
    return this.company.theme.planText ?? this.appColors.text.plan;
  }

  get valueCurrentChart() {
    return this.company.theme.currentValueChart ?? this.appColors.charts.sales;
  }
  get valueCurrentText() {
    return this.company.theme.currentValueText ?? this.appColors.text.sales;
  }

  get valuePrevChart() {
    return (
      this.company.theme.previousValueChart ?? this.appColors.charts.salesPrev
    );
  }
  get valuePrevText() {
    return (
      this.company.theme.previousValueText ?? this.appColors.text.salesPrev
    );
  }

  get fulfillmentChart() {
    return (
      this.company.theme.fulfillmentChart ?? this.appColors.charts.fulfillment
    );
  }
  get fulfillmentText() {
    return (
      this.company.theme.fulfillmentText ?? this.appColors.text.fulfillment
    );
  }
}
