import {
  CommonModule,
  CurrencyPipe,
  DatePipe,
  DecimalPipe,
  PercentPipe,
} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicStorageModule } from '@ionic/storage-angular';
import { TranslateModule } from '@ngx-translate/core';
import { DxChartModule, DxCircularGaugeModule } from 'devextreme-angular';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { VonPrimengFormModule } from 'src/app/von/primeng-form-validation/von-primeng-form.module';
import { AppChartComponent } from './components/app-chart';
import { AppChartCompleteComponent } from './components/app-chart-complete';
import { AppConfirmDialogComponent } from './components/app-confirm-dialog.component';
import { AppCustomerActionsComponent } from './components/app-customer-actions.component';
import { AppCustomerIconComponent } from './components/app-customer-icon.component';
import { AppDateFilterComponent } from './components/app-date-filter.component';
import { AppFulfillmentComponent } from './components/app-fulfillment';
import { AppGenericDialogComponent } from './components/app-generic-dialog.component';
import { AppHeadlineComponent } from './components/app-headline.component';
import { AppPageComponent } from './components/app-page.component';
import { LoadPanelComponent } from './components/load-panel.component';
import { AppPictureSelectorComponent } from './components/picture-selector.component';
import { AppStarRankingComponent } from './components/star-ranking';
import { DynamicMetricPipe } from './pipes/dynamic-metric.pipe';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { SortByPipe } from './pipes/sort-by.pipe';

const sharedComponents = [
  AppDateFilterComponent,

  AppConfirmDialogComponent,
  AppFulfillmentComponent,
  LoadPanelComponent,
  AppChartCompleteComponent,
  AppChartComponent,
  AppStarRankingComponent,
  LocalizedDatePipe,
  SortByPipe,
  DynamicMetricPipe,
  AppHeadlineComponent,
  AppPageComponent,
  AppCustomerIconComponent,
  AppCustomerActionsComponent,
  AppPictureSelectorComponent,
  AppGenericDialogComponent,
];

const thirdPartyModules = [
  ButtonModule,
  CalendarModule,
  ConfirmDialogModule,
  DialogModule,
  DropdownModule,
  InputGroupAddonModule,
  InputGroupModule,

  DxChartModule,
  DxCircularGaugeModule,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ...thirdPartyModules,
    IonicStorageModule,
    TranslateModule,
    VonPrimengFormModule,
  ],
  declarations: [...sharedComponents],
  providers: [MessageService, DatePipe, DecimalPipe, PercentPipe, CurrencyPipe],
  exports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ...sharedComponents,
    IonicStorageModule,
    TranslateModule,
    VonPrimengFormModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
