import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  Renderer2,
} from '@angular/core';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { BaseComponent } from 'src/app/core/base/base.component';

@Component({
  selector: '[app-page]',
  template: `<ng-content></ng-content>`,
})
export class AppPageComponent extends BaseComponent implements AfterViewInit {
  @Input() setHeight = true;
  @Input() styleName = 'height';

  constructor(
    override platform: Platform,
    override store: Store<any>,
    protected el: ElementRef,
    protected renderer: Renderer2
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.renderer.addClass(this.el.nativeElement, 'card');
    this.renderer.addClass(this.el.nativeElement, 'flex');
    this.renderer.addClass(this.el.nativeElement, 'flex-column');
    this.renderer.addClass(this.el.nativeElement, 'gap-2');
    this.renderer.addClass(this.el.nativeElement, 'overflow-y-auto');
    this.renderer.addClass(this.el.nativeElement, 'mb-0');
    this.renderer.addClass(this.el.nativeElement, 'p-3');
    this.renderer.addClass(this.el.nativeElement, 'md:p-4');

    this.onResize();
  }

  override onResize = () => {
    if (this.setHeight) {
      this.renderer.setStyle(
        this.el.nativeElement,
        this.styleName,
        `${this.screenHeight}px`
      );
    }
  };
}
