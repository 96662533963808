<nav class="layout-breadcrumb" *ngIf="!hideBreadcrumb">
  <ol>
    <li>
      <a [routerLink]="['/']">
        <i class="pi pi-home"></i>
      </a>
    </li>
    <li class="layout-breadcrumb-chevron">/</li>
    <ng-template ngFor let-item let-last="last" [ngForOf]="breadcrumbs$ | async">
      <li *ngIf="item.url !== '/p360'">
        <a [routerLink]="[item.url]" *ngIf="!last && item.url !== '/p360'">{{ item.label | translate }}</a>
        <span *ngIf="last && item.url !== '/p360'">{{ item.label | translate }}</span>
      </li>
      <li *ngIf="!last && item.url !== '/p360'" class="layout-breadcrumb-chevron">/</li>
    </ng-template>
  </ol>
</nav>
