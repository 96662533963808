<div class="layout-topbar" [ngClass]="{ 'layout-topbar--mobile-landscape': isSmall && !isPortrait }">
  <a class="app-logo" routerLink="/">
    <img alt="app logo" src="{{ 'LOGO_ICON' | translate }}" />
    <span class="app-name">{{ "APP" | translate }}</span>
  </a>

  <button #menubutton class="topbar-menubutton p-link" type="button" (click)="onMenuButtonClick()">
    <span></span>
  </button>

  <ul class="topbar-menu">
    <app-breadcrumb *ngIf="!isMedium"></app-breadcrumb>
  </ul>

  <div class="topbar-search gap-3">
    <span class="icon__wrapper text-red-500" [style.height.px]="38" [style.width.px]="38" [ngClass]="{ 'icon__fixed text-red-500': !connected, 'text-green-500': connected }">
      <i class="fa-solid fa-wifi text-xl"></i>
    </span>

    <button class="topbar-searchbutton p-link" type="button" *ngIf="appointmentsToSync + ordersToSync + customersToSync" (click)="offlineActions.toggle($event)">
      <i class="fa-solid fa-rotate" pBadge [value]="totalToSync"></i>
    </button>

    <button class="topbar-searchbutton p-link" type="button" [routerLink]="['/p360/notificacion/todos']">
      <i class="pi pi-inbox" *ngIf="notificationsCounter === 0"></i>
      <i class="pi pi-inbox" pBadge [value]="notificationsCounter + ''" *ngIf="notificationsCounter !== 0"></i>
    </button>

    <button class="topbar-searchbutton p-link" type="button" *ngIf="isSmall" (click)="handleEnableScreenRotation()">
      <span class="material-icons text-color" *ngIf="!rotationUnlocked">screen_lock_rotation</span>
      <span class="material-icons text-green-500" *ngIf="rotationUnlocked">screen_rotation</span>
    </button>
  </div>

  <div class="topbar-profile">
    <button class="topbar-profile-button p-link" type="button" pStyleClass="@next" enterFromClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
      <img alt="avatar" [src]="collaborator?.photoUrl" />
      <span class="profile-details">
        <span class="profile-name">{{ collaborator?.agentName }}</span>
        <span class="profile-job">{{ collaborator?.role }}</span>
      </span>
      <i class="pi pi-angle-down"></i>
    </button>
    <ul class="list-none p-3 m-0 border-round shadow-2 hidden absolute surface-overlay origin-top w-full sm:w-12rem mt-2 right-0 top-auto">
      <li>
        <a pRipple class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer" (click)="showPasswordChange = true">
          <i class="pi pi-lock mr-3"></i>
          <span translate="Cambia tu contraseña"></span>
        </a>
        <a pRipple class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer" (click)="onLogoutClick()">
          <i class="pi pi-power-off mr-3"></i>
          <span translate="LOGOUT"></span>
        </a>
      </li>
    </ul>
  </div>
</div>

<p-overlayPanel #offlineActions>
  <section class="flex flex-column align-items-center justify-content-center gap-2">
    <p-button type="button" [outlined]="true" severity="primary" [routerLink]="['/p360/visitas-pendientes']" *ngIf="appointmentsToSync > 0" (onClick)="offlineActions.toggle($event)">
      <img alt="appointment sync" src="/assets/imgs/crm/add-appointment.png" height="24px" width="24px" />
      <span class="ml-2" translate="APPOINTMENTS"></span>
      <p-badge [value]="appointmentsToSync + ''" severity="info"></p-badge>
    </p-button>

    <p-button type="button" [outlined]="true" severity="primary" [routerLink]="['/p360/pedidos-pendientes']" *ngIf="ordersToSync > 0" (onClick)="offlineActions.toggle($event)">
      <img alt="order sync" src="/assets/imgs/crm/add-order.png" height="24px" width="24px" />
      <span class="ml-2" translate="ORDERS"></span>
      <p-badge [value]="ordersToSync + ''" severity="info"></p-badge>
    </p-button>

    <p-button type="button" [outlined]="true" severity="primary" [routerLink]="['/p360/clientes-pendientes']" *ngIf="customersToSync > 0" (onClick)="offlineActions.toggle($event)">
      <img alt="customer sync" src="/assets/imgs/crm/add-client.png" height="24px" width="24px" />
      <span class="ml-2" translate="CUSTOMERS"></span>
      <p-badge [value]="customersToSync + ''" severity="info"></p-badge>
    </p-button>
  </section>
</p-overlayPanel>

<p-dialog
  [(visible)]="showPasswordTemporal || showPasswordChange"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [dismissableMask]="showPasswordChange"
  [closable]="showPasswordChange"
  position="top"
  [style]="{ maxWidth: '425px', width: '90vw', maxHeight: '90vh' }"
  appendTo="body"
>
  <form class="flex flex-column align-items-center justify-content-center gap-2" (validate)="handlePasswordChange()" novalidate validation-es>
    <ng-container *ngIf="showPasswordChange && collaborator">
      <h2 class="m-0 text-center">
        {{ collaborator.agentName }}
      </h2>

      <span>
        <img class="collaborator_img" alt="collaborator" [src]="collaborator?.photoUrl" />
      </span>

      <span>
        {{ collaborator.role }}
      </span>
    </ng-container>

    <h3 class="text-lg m-0 text-center font-semibold mb-4" *ngIf="showPasswordTemporal">
      {{ "Por la seguridad de tus datos y la empresa, necesitamos que cambies la contraseña temporal." | translate }}
    </h3>
    <h3 class="text-lg m-0 text-center font-semibold mb-2" *ngIf="showPasswordChange">
      {{ "Ingresa tu nueva contraseña." | translate }}
    </h3>

    <span class="p-float-label w-full">
      <p-password
        name="newPassword"
        [(ngModel)]="newPassword"
        [toggleMask]="true"
        validation
        [required]="true"
        [requiredMessage]="'Ingresa tu nueva contraseña' | translate"
        styleClass="w-full"
        inputStyleClass="w-full"
        appendTo="body"
      ></p-password>
      <label for="newPassword">
        {{ "Nueva contraseña" | translate }}
      </label>
    </span>

    <span></span>

    <span class="p-float-label w-full">
      <p-password
        name="newPasswordConfirm"
        [(ngModel)]="newPasswordConfirm"
        [feedback]="false"
        [toggleMask]="true"
        validation
        [equalTo]="newPassword"
        [equalToMessage]="'Las contraseñas no coinciden' | translate"
        styleClass="w-full"
        inputStyleClass="w-full"
      ></p-password>
      <label for="newPasswordConfirm">
        {{ "Confirmar Nueva contraseña" | translate }}
      </label>
    </span>

    <span class="w-full text-right">
      <p-button type="submit" icon="fa-solid fa-save" [label]="'Actualziar' | translate" [loading]="passwordSaving"></p-button>
    </span>
  </form>
</p-dialog>
