import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin, from, map, Observable, switchMap } from 'rxjs';
import { AppStorage } from 'src/app/core/utils/app.storage';
import { AppStorageService } from 'src/app/core/utils/app.storage.service';

export class MultiTranslateLoader implements TranslateLoader {
  constructor(
    private readonly http: HttpClient,
    private readonly storage: AppStorageService
  ) {}

  getTranslation(lang: string): Observable<Record<string, string>> {
    const languageObservable = this.http.get<Record<string, string>>(
      `./assets/i18n/${lang}.json`
    );
    const titlesAndHeadingsObservable = this.http.get<Record<string, string>>(
      `./assets/i18n/titles-and-headings/${lang}.json`
    );
    const primengObservable = this.http.get<Record<string, string>>(
      `./assets/i18n/primeng/${lang}.json`
    );
    const devextremeObservable = this.http.get<Record<string, string>>(
      `./assets/i18n/devextreme/${lang}.json`
    );
    const formsObservable = this.http.get<Record<string, string>>(
      `./assets/i18n/forms/${lang}.json`
    );
    const messagesObservable = this.http.get<Record<string, string>>(
      `./assets/i18n/messages/${lang}.json`
    );

    const typeObservable = from(this.storage.get(AppStorage.COMPANY_TYPE)).pipe(
      switchMap((type) =>
        forkJoin([
          this.http.get<Record<string, string>>(
            `./assets/i18n/${type ?? 'pharma'}/${lang}.json`
          ),
        ])
      ),
      map((res) => res[0])
    );

    return forkJoin([
      languageObservable,
      titlesAndHeadingsObservable,
      primengObservable,
      devextremeObservable,
      formsObservable,
      messagesObservable,
      typeObservable,
    ]).pipe(map(mergeObjectsRecursively));
  }
}

export const mergeObjectsRecursively = (
  objects: Record<string, string>[]
): Record<string, string> => {
  const mergedObject: Record<string, any> = {};

  for (const obj of objects) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          mergedObject[key] = mergeObjectsRecursively([
            mergedObject[key],
            obj[key],
          ]);
        } else {
          mergedObject[key] = obj[key];
        }
      }
    }
  }

  return mergedObject;
};
