import { formatDate } from '@angular/common';
import {
  HttpEvent,
  HttpHandler,
  HttpParams,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  VonErrorRestInterceptorModel,
  VonRestInterceptorService,
} from '@von-development-studio/angular-rest-service';
import { CookieService } from 'ngx-cookie-service';
import { from, Observable, throwError } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { ResponseDto } from 'src/app/models/app.response-dto';
import { AppConstants } from 'src/app/core/utils/app.const';
import { AppStorage } from 'src/app/core/utils/app.storage';
import { AppStorageService } from 'src/app/core/utils/app.storage.service';
import { AppMessageService } from './app-message.service';
import { RestService } from './rest.service';

@Injectable()
export class RestInterceptorService extends VonRestInterceptorService {
  redirectOn403 = false;

  constructor(
    override router: Router,
    protected store: Store<any>,
    protected cookie: CookieService,
    protected message: AppMessageService,
    protected storage: AppStorageService
  ) {
    super(router);
  }

  override intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const keys = request.params.keys();
    const params: any = {};
    for (const key of keys) {
      if (key === 'date') {
        // TODO: Verify translate service for current language
        const formattedDate = formatDate(
          request.params.get(key)!,
          'yyyy-MM-dd',
          'ES'
        );
        params[key] = formattedDate;
      } else {
        params[key] = request.params.get(key);
      }
    }

    const toClone: any = { params: new HttpParams({ fromObject: params }) };
    toClone.withCredentials = false;

    if (
      !navigator.onLine &&
      (request.url.indexOf('p360security.bisigma.com') > -1 ||
        request.url.indexOf('/api/v2') > -1)
    ) {
      let general = 'MESSAGE.NO_INTERNET';
      if (request.url.indexOf(AppConstants.REST_ENDPOINTS.GEN_0002) > -1) {
        general = 'MESSAGE.NO_INTERNET_ITEMS';
      }
      throw {
        status: 500,
        message: `MESSAGE.204_PREFIX#${general}`,
      };
    }

    return from(this.storage.get<string>(AppStorage.SESSION)).pipe(
      mergeMap((sessionToken) => {
        if (sessionToken || RestService.session) {
          toClone.setHeaders = {
            'Session-ID': sessionToken,
          };
        }
        const clonedRequest = request.clone(toClone);
        return super.intercept(clonedRequest, next).pipe(
          tap({
            next: (event: HttpEvent<ResponseDto<any>>) => {
              if (event instanceof HttpResponse) {
                if (
                  event.url.indexOf('openstreetmap') > -1 ||
                  event.url.indexOf('p360security.bisigma.com') > -1 ||
                  event.url.indexOf(AppConstants.REST_ENDPOINTS.HEALTHCHECK) >
                    -1
                ) {
                  return;
                }

                if (event.status === 200) {
                  const customCode = event.body.code ?? '';
                  const customMessage: string = event.body.message ?? '';
                  if (customCode === 'ERR-204') {
                    console.warn(
                      `WARNING! Endpoint ${event.url} didn't return information.`
                    );
                    throw {
                      status: 204,
                      message: `MESSAGE.204_PREFIX#${customMessage}`,
                    };
                  } else if (customCode === 'ERR-500' || customMessage) {
                    console.error(
                      `ERROR! Unexpected error on endpoint ${event.url}.`
                    );
                    throw { status: 500, message: customMessage };
                  } else if (customCode === 'ERR-408' || customMessage) {
                    console.error(
                      `ERROR! Timeout request on endpoint ${event.url}.`
                    );
                    throw { status: 408, message: customMessage };
                  }

                  // TODO: Verify the best wayt to manage a cache...
                  // this.saveCache(
                  //   event.body,
                  //   clonedRequest.urlWithParams,
                  //   clonedRequest.method
                  // );
                } else if (event.status === 204) {
                  console.warn(
                    `WARNING! Endpoint ${event.url} didn't return information. Show generic message`
                  );
                  throw {
                    status: 204,
                    message:
                      event.body.message ??
                      'MESSAGE.204_PREFIX#MESSAGE.204_GENERIC',
                  };
                } else if (event.status === 500) {
                  const customMessage: string = event.body.message ?? '';
                  console.error(
                    `ERROR! Unexpected error on endpoint ${event.url}.`
                  );
                  throw {
                    status: 500,
                    message: customMessage ?? 'MESSAGE.500_GENERIC',
                  };
                }
              }
            },
            error: (err: any) => {
              if (
                request.url.indexOf(AppConstants.REST_ENDPOINTS.LOGIN) > -1 ||
                request.url.indexOf(AppConstants.REST_ENDPOINTS.HEALTHCHECK) >
                  -1
              ) {
                return;
              }

              if (!navigator.onLine) {
                let general = 'MESSAGE.NO_INTERNET';
                if (
                  request.url.indexOf(AppConstants.REST_ENDPOINTS.GEN_0002) > -1
                ) {
                  general = 'MESSAGE.NO_INTERNET_ITEMS';
                }
                throw {
                  status: 500,
                  message: `MESSAGE.204_PREFIX#${general}`,
                };
              } else {
                let errorCode: string = AppConstants.REST_UNKNOWN_ERROR;
                let errorMsg;
                if (err.status === 400) {
                  errorCode = AppConstants.REST_BAD_REQUEST;
                } else if (err.status === 404) {
                  errorCode = AppConstants.REST_NOT_FOUND;
                } else if (err.status === 500 && err.body) {
                  errorMsg = `MESSAGE.204_PREFIX#${err.body.message}`;
                }

                console.error(
                  `FATAL BY ERROR! ${clonedRequest.url}... ${JSON.stringify(
                    err
                  )})}`
                );
                throw {
                  status: 500,
                  message:
                    errorMsg ??
                    `MESSAGE.500_GENERIC#<span class=\"text-xs font-italic"> ${errorCode}</span>`,
                };
              }
            },
          }),
          catchError((error) => this.innerCatchError(error, request.url))
        );
      })
    );
  }

  protected saveCache = (
    result: ResponseDto<any>,
    requestUrl: string,
    requestMethod: string
  ) => {
    if (
      requestUrl.indexOf('api/v2') > -1 &&
      requestMethod.toUpperCase() !== 'GET'
    ) {
      return;
    }
    this.storage.set(requestUrl, result);
    this.storage.get(AppStorage.CACHE_KEYS).then((value) => {
      if (
        requestUrl.indexOf('assets/i18n') === -1 &&
        requestUrl.indexOf('null') === -1
      ) {
        if (value) {
          this.storage.set(AppStorage.CACHE_KEYS, `${value}||${requestUrl}`);
        } else {
          this.storage.set(AppStorage.CACHE_KEYS, requestUrl);
        }
      }
    });
  };

  protected innerCatchError = (
    errorResponse: VonErrorRestInterceptorModel | string,
    requestUrl: string
  ) => {
    if (this.checkApiToIgnoreMessage(requestUrl)) {
      return throwError(() => errorResponse);
    }

    if (requestUrl.indexOf(AppConstants.REST_ENDPOINTS.HEALTHCHECK) > -1) {
      throw {
        status: 500,
        message: 'MESSAGE.204_PREFIX#MESSAGE.500_GENERIC',
      };
    } else {
      if (typeof errorResponse === 'string') {
        const sticky =
          requestUrl.indexOf(AppConstants.REST_ENDPOINTS.GEN_0002) > -1 ||
          errorResponse.indexOf('MESSAGE.NO_INTERNET_ITEMS') > -1;
        this.message.addError(errorResponse, sticky);
      } else {
        const { status, message, body: error } = errorResponse;
        const toastMessage = message ?? error;
        const sticky =
          requestUrl.indexOf(AppConstants.REST_ENDPOINTS.GEN_0002) > -1 ||
          toastMessage.indexOf('MESSAGE.NO_INTERNET_ITEMS') > -1;
        const messageService =
          status === 204 ? this.message.addWarning : this.message.addError;
        messageService(toastMessage, {}, sticky);
      }
    }

    return throwError(() => errorResponse);
  };

  private checkApiToIgnoreMessage = (requestUrl: string) => {
    return (
      requestUrl.indexOf('p360security.bisigma.com') > -1 ||
      requestUrl.indexOf(AppConstants.REST_ENDPOINTS.LOGIN) > -1 ||
      requestUrl.indexOf(AppConstants.REST_ENDPOINTS.GEN_0003) > -1 ||
      requestUrl.indexOf(AppConstants.REST_ENDPOINTS.SAVE_APPOINTMENT) > -1 ||
      requestUrl.indexOf(AppConstants.REST_ENDPOINTS.SAVE_ORDER) > -1 ||
      requestUrl.indexOf(AppConstants.REST_ENDPOINTS.PRE_ORDER) > -1
    );
  };
}
