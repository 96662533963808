import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { from, mergeMap, Observable, of } from 'rxjs';
import { AppStorage } from 'src/app/core/utils/app.storage';
import { AppStorageService } from 'src/app/core/utils/app.storage.service';

@Injectable()
export class AuthGuard {
  constructor(
    private router: Router,
    protected cookie: CookieService,
    protected appStorageService: AppStorageService
  ) {}

  canActivate(): Observable<boolean> {
    return from(this.appStorageService.get<string>(AppStorage.SESSION)).pipe(
      mergeMap((sessionToken) => {
        if (sessionToken && sessionToken != null && sessionToken !== 'null') {
          return of(true);
        } else {
          this.router.navigate(['/inicio-sesion']);
          return of(false);
        }
      })
    );
  }
}
