export class AgentModel {
  public document?: string;
  public agentCode?: number;
  public agentName?: string;
  public photo = '';
  public company: CompanyModel = new CompanyModel();
  public user: UserModel = new UserModel();
  constructor() {}
}

export class CompanyModel {
  public code?: string;
  public name?: string;
  public host?: string;
  public logo?: string;
  constructor() {}
}

export class UserModel {
  public document?: string;
  public agentCode?: number;
  public agentName?: string;
  public lines?: string;
  public supervisorCode?: string;
  public supervisorName?: string;
  public email?: string;
  public phone?: string;
  public roleCode?: string;
  public role?: string;
  public roleType?: string;
  public cycle?: string;
  constructor() {}
}

export class HomeDetailOptionModel {
  public list: any[];
  public option: any;
  public selected: string;
  public alt: any;
  constructor() {
    this.list = [
      {
        id: 0,
        label: 'CITY',
        value: 'city',
        icon: 'far fa-fw fa-map',
      },
      {
        id: 1,
        label: 'CUSTOMER',
        value: 'customer',
        icon: 'fas fa-fw fa-user-md',
      },
    ];
    this.option = this.list[0];
    this.alt = this.list[1];
    this.selected = this.option.value;
  }
  public updateOptionSelected = () => {
    let idx = 0;
    this.selected = this.option.value;
    if (this.selected === 'city') {
      idx = 1;
    }
    this.alt = this.list[idx];
  };
}

/** @deprecated Use MeasureType interface instead */
export class MeasureType {
  public plan: number;
  public sales: number;
  public bonif?: number;
  public fulfillment?: number;
  constructor(
    plan: number = 0,
    sales: number = 0,
    bonif: number = 0,
    fulfillment: number = 0
  ) {
    this.plan = plan;
    this.sales = sales;
    if (bonif != null) {
      this.bonif = bonif;
    }
    if (fulfillment != null) {
      this.fulfillment = fulfillment;
    }
  }
}

/** @deprecated Use Measure interface instead */
export class Measures {
  public value: MeasureType;
  public units: MeasureType;
  constructor(defValue?: MeasureType, defUnits?: MeasureType) {
    this.value = new MeasureType();
    if (defValue != null) {
      this.value.plan = defValue.plan;
      this.value.sales = defValue.sales;
      this.value.bonif = defValue.bonif;
      this.value.fulfillment = defValue.fulfillment;
    }

    this.units = new MeasureType();
    if (defUnits != null) {
      this.units.plan = defUnits.plan;
      this.units.sales = defUnits.sales;
      this.units.bonif = defUnits.bonif;
      this.units.fulfillment = defUnits.fulfillment;
    }
  }
}

/** @deprecated Use Interface instead */
export class HistoryType {
  public current?: MeasureType;
  public previous?: MeasureType;
}

/** @deprecated Use interface instead */
export class DataRangered {
  public name: string;
  public mat: HistoryType;
  public qtd: HistoryType;
  public mth: HistoryType;
  public matSales: number;
  public qtdSales: number;
  public mthSales: number;
  constructor(
    name: string,
    mat: HistoryType,
    qtd: HistoryType,
    mth: HistoryType,
    matSales: number = 0,
    qtdSales: number = 0,
    mthSales: number = 0
  ) {
    this.name = name;
    this.mat = mat;
    this.qtd = qtd;
    this.mth = mth;
    this.matSales = matSales;
    this.qtdSales = qtdSales;
    this.mthSales = qtdSales;
  }
}

/** @deprecated */
export class GroupedTypeOption {
  public groups: any[];
  public option: any;
  public selected: string;
  constructor() {
    this.groups = [
      {
        id: 0,
        labelToShow: 'DATE_TYPE_MONTHLY',
        label: 'DATE_TYPE_MONTHLY_SHORT',
        value: 'M',
      },
      {
        id: 1,
        labelToShow: 'DATE_TYPE_CUMULATIVE',
        label: 'DATE_TYPE_CUMULATIVE_SHORT',
        value: 'A',
      },
    ];
    this.option = this.groups[0];
    this.selected = this.option.value;
  }
}

export class FullTypesModel {
  plan?: number;
  sales?: number;
  salesPrev?: number;
  bonif?: number;
  bonifPrev?: number;
  fulfillment?: number;
  total?: number;
  totalPrev?: number;
}

export class ChartModel {
  date?: Date;
  units?: FullTypesModel;
  value?: FullTypesModel;
}

export class PharmaColorsTypesModel {
  public plan: string;
  public sales: string;
  public salesPrev: string;
  public fulfillment: string;
  public fulfillmentPrev: string;
  public projection: string;
  constructor(
    plan: string,
    sales: string,
    salesPrev: string,
    fulfillment: string,
    fulfillmentPrev: string,
    projection: string
  ) {
    this.plan = plan;
    this.sales = sales;
    this.salesPrev = salesPrev;
    this.fulfillment = fulfillment;
    this.fulfillmentPrev = fulfillmentPrev;
    this.projection = projection;
  }
}

export class PharmaColorsModel {
  public primary = '#8A0808';
  public secondary = '#FDFAFA';
  public lowFulfillment = '#f5564a';
  public normalFulfillment = '#ffc721';
  public highFulfillment = '#96c95c';

  public text: PharmaColorsTypesModel = new PharmaColorsTypesModel(
    '#DBA901', // plan
    '#000000', // sales
    '#3E3E3E', // salesPrev
    '#0B088A', // fulfillment
    '#4846A7', // fulfillmentPrev
    '#5CB85C' // projection
  );

  public charts: PharmaColorsTypesModel = new PharmaColorsTypesModel(
    '#FDF3DD', // plan
    '#3CBAB2', // sales
    '#CEEEEC', // salesPrev
    '#0B088A', // fulfillment
    '#E1E0F0', // fulfillmentPrev
    '#5CB85C' // projection
  );

  constructor() {}
}

export class TypeOption {
  public types: any[];
  public selected: string;
  public option: any;
  constructor() {
    this.types = [
      {
        id: 0,
        title: 'TYPE_DOLLAR',
        value: 'value',
        icon: 'fas fa-fw fa-dollar-sign',
      },
      { id: 1, title: 'TYPE_UNITS', value: 'units', icon: 'p360 u-icon' },
    ];
    this.selected = 'value';
    this.option = this.types[0];
  }
}
