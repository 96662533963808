<div class="w-full">
  <p-divider align="center" layout="horizontal">
    <span class="p-tag">&amp;</span>
  </p-divider>
</div>

<div class="col-12 flex flex-wrap gap-3 p-0">
  <div class="col-12 md:col p-0 md:pb-3 flex flex-column">
    <label class="font-bold" for="rapidFilter">{{ "RAPID_FILTER" | translate }}</label>

    <div class="w-full">
      <p-dropdown
        validation
        [(ngModel)]="collabRapidFiltersSelected"
        (onChange)="handleRapidFilterChange($event)"
        name="filtersRapidFilter"
        [options]="collabRapidFiltersOptions"
        optionLabel="label"
        optionValue="id"
        styleClass="w-full"
        appendTo="body"
        *ngIf="isSalesforce"
      >
        <ng-template let-item pTemplate="selectedItem">
          <span [translate]="item.label"></span>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <span [translate]="item.label"></span>
        </ng-template>
      </p-dropdown>

      <p-dropdown
        validation
        [(ngModel)]="rapidFiltersSelected"
        (onChange)="handleRapidFilterChange($event)"
        name="filtersRapidFilter"
        [options]="rapidFiltersOptions"
        optionLabel="label"
        optionValue="id"
        styleClass="w-full"
        appendTo="body"
        *ngIf="!isSalesforce"
      >
        <ng-template let-item pTemplate="selectedItem">
          <span [translate]="item.label"></span>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <span [translate]="item.label"></span>
        </ng-template>
      </p-dropdown>
    </div>
  </div>

  <div [ngClass]="{ 'col-12 p-0': isSmall }">
    <p-divider align="center" [layout]="isSmall ? 'horizontal' : 'vertical'">
      <span class="p-tag" *ngIf="isSmall">&amp;</span>
    </p-divider>
  </div>

  <div class="col-12 md:col p-0 md:pb-3 flex flex-column">
    <label class="font-bold" for="measureFilter">{{ "MEASURE" | translate }}</label>

    <div class="w-full">
      <p-dropdown
        validation
        #measureFilter
        [(ngModel)]="measureSelected"
        (onChange)="handleMeasureChange($event)"
        name="measureFilter"
        [options]="measureOptions"
        optionLabel="label"
        optionValue="value"
        styleClass="w-full"
        appendTo="body"
      >
        <ng-template let-item pTemplate="selectedItem">
          <span [translate]="item.label"></span>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <span [translate]="item.label"></span>
        </ng-template>
      </p-dropdown>
    </div>
  </div>
</div>

<div class="text-right mt-2">
  <p-button icon="pi pi-eraser text-2xl" type="button" [outlined]="true" [rounded]="true" severity="danger" [text]="true" (onClick)="clearFilters()"></p-button>
</div>
