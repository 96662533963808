import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Dropdown, DropdownChangeEvent } from 'primeng/dropdown';
import { BaseComponent } from 'src/app/core/base/base.component';
import { Client } from 'src/app/models/client';
import { Collaborator } from 'src/app/models/collaborator';
import { P360DropdownItem } from 'src/app/models/common';
import { Line } from 'src/app/models/line';
import { Segment } from 'src/app/models/segment';
import { appStoreFiltersActions } from 'src/app/store/filters/store.filters.action';
import { AppStorageService } from 'src/app/core/utils/app.storage.service';

@Component({
  template: '',
})
export abstract class BaseFiltersComponent
  extends BaseComponent
  implements OnInit
{
  @ViewChild('customerFilter') clientFilter?: Dropdown;
  @ViewChild('lineFilter') lineFilter?: Dropdown;
  @ViewChild('segmentFilter') segmentFilter?: Dropdown;
  @ViewChild('collaboratorFilter') collaboratorFilter?: Dropdown;
  @ViewChild('regionFilter') regionFilter?: Dropdown;

  @Output() onClear = new EventEmitter<void>();

  clientOptions: P360DropdownItem<Client>[] = [];
  clientSelected?: number;

  lineOptions: P360DropdownItem<Line>[] = [];
  lineSelected?: number;

  segmentOptions: P360DropdownItem<Segment>[] = [];
  segmentSelected?: number;

  collaboratorOptions: P360DropdownItem<Collaborator>[] = [];
  collaboratorSelected?: number;

  regionOptions: P360DropdownItem<string>[] = [];
  regionSelected?: string;

  triggerOr = true;

  constructor(
    override store?: Store<any>,
    protected storage?: AppStorageService,
    protected renderer?: Renderer2,
    protected translate?: TranslateService
  ) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.store
      ?.select('filters', 'clientForFiltersFilterList')
      .subscribe((clientFilterList) => {
        this.clientOptions = clientFilterList;
      });

    this.store
      ?.select('filters', 'clientForFiltersFilterOption')
      .subscribe((clientFilterOption) => {
        this.clientSelected = clientFilterOption?.id;
        const clientValue = clientFilterOption?.code;

        if (clientValue) {
          this.manualChangeDomForFilter(clientValue, this.clientFilter);
        }
      });

    this.store
      ?.select('filters', 'lineFilterList')
      .subscribe((lineFilterList) => {
        this.lineOptions = lineFilterList;
      });

    this.store
      ?.select('filters', 'lineFilterOption')
      .subscribe((lineFilterOption) => {
        this.lineSelected = lineFilterOption?.id;
        const lineValue = lineFilterOption?.code;

        if (lineValue === 'TODOS') {
          this.manualChangeDomForFilter(lineValue, this.lineFilter);
        }
      });

    this.store
      ?.select('filters', 'segmentFilterList')
      .subscribe((segmentFilterList) => {
        this.segmentOptions = segmentFilterList;
      });

    this.store
      ?.select('filters', 'segmentFilterOption')
      .subscribe((segmentFilterOption) => {
        this.segmentSelected = segmentFilterOption?.id;
        const segmentValue = segmentFilterOption?.code;

        if (segmentValue === 'TODOS') {
          this.manualChangeDomForFilter(segmentValue, this.segmentFilter);
        }
      });

    this.store
      ?.select('filters', 'collaboratorFilterList')
      .subscribe((collaboratorFilterList) => {
        this.collaboratorOptions = collaboratorFilterList;
      });

    this.store
      ?.select('filters', 'collaboratorFilterOption')
      .subscribe((collaboratorFilterOption) => {
        this.collaboratorSelected = collaboratorFilterOption?.id;
        const collaboratorValue = collaboratorFilterOption?.code;

        if (collaboratorValue === 'TODOS') {
          this.manualChangeDomForFilter(
            collaboratorValue,
            this.collaboratorFilter
          );
        }
      });

    this.store
      ?.select('filters', 'regionFilterList')
      .subscribe((regionFilterList) => {
        this.regionOptions = regionFilterList;
      });

    this.store
      ?.select('filters', 'regionFilterOption')
      .subscribe((regionFilterOption) => {
        this.regionSelected = regionFilterOption.value;
      });
  }

  onClientChange = ($ev: DropdownChangeEvent) => {
    const { value } = $ev;
    const filterOption = this.clientOptions.find(
      (item) => item.id === value
    )?.value;

    if (filterOption) {
      this.store?.dispatch(
        appStoreFiltersActions.setClientForFiltersOptionAction({
          clientForFiltersFilterOption: filterOption,
        })
      );
    }

    this.customOnClientChange($ev);
  };
  customOnClientChange = (_: DropdownChangeEvent) => {};

  onLineChange = ($ev: DropdownChangeEvent) => {
    const { value } = $ev;
    const filterOption = this.lineOptions.find(
      (item) => item.id === value
    )?.value;

    if (filterOption) {
      this.store?.dispatch(
        appStoreFiltersActions.setLineOptionAction({
          lineFilterOption: filterOption,
        })
      );
    }

    if (this.triggerOr && this.lineFilter) {
      this.store?.dispatch(appStoreFiltersActions.clearSegmentFilterAction());
    }

    if (this.triggerOr && this.collaboratorFilter) {
      this.store?.dispatch(
        appStoreFiltersActions.clearCollaboratorFilterAction()
      );
    }

    if (this.triggerOr && this.regionFilter) {
      this.store?.dispatch(appStoreFiltersActions.clearRegionFilterAction());
    }

    this.customOnLineChange($ev);
  };
  customOnLineChange = (_: DropdownChangeEvent) => {};

  onSegmentChange = ($ev: DropdownChangeEvent) => {
    const { value } = $ev;
    const filterOption = this.segmentOptions.find(
      (item) => item.id === value
    )?.value;

    if (filterOption) {
      this.store?.dispatch(
        appStoreFiltersActions.setSegmentOptionAction({
          segmentFilterOption: filterOption,
        })
      );
    }

    if (this.triggerOr && this.lineFilter) {
      this.store?.dispatch(appStoreFiltersActions.clearLineFilterAction());
    }

    if (this.triggerOr && this.collaboratorFilter) {
      this.store?.dispatch(
        appStoreFiltersActions.clearCollaboratorFilterAction()
      );
    }

    if (this.triggerOr && this.regionFilter) {
      this.store?.dispatch(appStoreFiltersActions.clearRegionFilterAction());
    }

    this.customOnSegmentChange($ev);
  };
  customOnSegmentChange = (_: DropdownChangeEvent) => {};

  onCollaboratorChange = ($ev: DropdownChangeEvent) => {
    const { value } = $ev;
    const filterOption = this.collaboratorOptions.find(
      (item) => item.id === value
    )?.value;

    if (filterOption) {
      this.store?.dispatch(
        appStoreFiltersActions.setCollaboratorOptionAction({
          collaboratorFilterOption: filterOption,
        })
      );
    }

    if (this.triggerOr && this.lineFilter) {
      this.store?.dispatch(appStoreFiltersActions.clearLineFilterAction());
    }

    if (this.triggerOr && this.segmentFilter) {
      this.store?.dispatch(appStoreFiltersActions.clearSegmentFilterAction());
    }

    if (this.triggerOr && this.regionFilter) {
      this.store?.dispatch(appStoreFiltersActions.clearRegionFilterAction());
    }

    this.customOnCollaboratorChange($ev);
  };
  customOnCollaboratorChange = (_: DropdownChangeEvent) => {};

  onRegionChange = ($ev: DropdownChangeEvent) => {
    const { value } = $ev;
    const filterOption = this.regionOptions.find(
      (item) => item.value === value
    );

    if (filterOption) {
      this.store?.dispatch(
        appStoreFiltersActions.setRegionFilterOptionAction({
          regionFilterOption: filterOption,
        })
      );
    }

    if (this.triggerOr && this.lineFilter) {
      this.store?.dispatch(appStoreFiltersActions.clearLineFilterAction());
    }

    if (this.triggerOr && this.segmentFilter) {
      this.store?.dispatch(appStoreFiltersActions.clearSegmentFilterAction());
    }

    if (this.triggerOr && this.collaboratorFilter) {
      this.store?.dispatch(
        appStoreFiltersActions.clearCollaboratorFilterAction()
      );
    }

    this.customOnRegionChange($ev);
  };
  customOnRegionChange = (_: DropdownChangeEvent) => {};

  clearFilters = () => {
    this.onClear.emit();

    if (this.clientFilter) {
      this.store?.dispatch(
        appStoreFiltersActions.clearClientForFiltersFilterAction()
      );
    }

    if (this.lineFilter) {
      this.store?.dispatch(appStoreFiltersActions.clearLineFilterAction());
    }

    if (this.segmentFilter) {
      this.store?.dispatch(appStoreFiltersActions.clearSegmentFilterAction());
    }

    if (this.collaboratorFilter) {
      this.store?.dispatch(
        appStoreFiltersActions.clearCollaboratorFilterAction()
      );
    }

    if (this.regionFilter) {
      this.store?.dispatch(appStoreFiltersActions.clearRegionFilterAction());
    }

    this.customClearFilters();
  };

  abstract customClearFilters: () => void;

  private manualChangeDomForFilter = (value: any, filter?: Dropdown) => {
    this.translate?.get(value).subscribe((label) => {
      this.renderer?.setProperty(
        filter?.el.nativeElement.querySelector('.p-dropdown-label>span'),
        'innerHTML',
        label
      );
    });
  };
}
