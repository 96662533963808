import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseComponent } from 'src/app/core/base/base.component';

@Component({
  selector: 'app-generic-dialog, [app-generic-dialog]',
  templateUrl: './app-generic-dialog.component.html',
})
export class AppGenericDialogComponent extends BaseComponent {
  @Input() header: string = '';
  @Input() showDialog: boolean = false;
  @Input() dismissableMask: boolean = true;
  @Input() closable: boolean = true;
  @Input() position:
    | 'center'
    | 'right'
    | 'left'
    | 'top'
    | 'bottom'
    | 'topleft'
    | 'topright'
    | 'bottomleft'
    | 'bottomright' = 'top';
  @Input() maxWidth?: string;

  @Output() showDialogChange = new EventEmitter<boolean>();

  constructor(override store: Store<any>) {
    super();
  }

  handleCloseDialog = () => {
    this.showDialogChange.emit(false);
  };
}
