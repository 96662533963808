import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { FirebaseAnalytics } from '@awesome-cordova-plugins/firebase-analytics/ngx';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { Globalization } from '@awesome-cordova-plugins/globalization/ngx';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { HandleAppVersionService } from './plugins/handle.app-version.service';
import { HandleFirebaseAnalyticsService } from './plugins/handle.firebase-analytics.service';
import { HandleFirebaseService } from './plugins/handle.firebase.service';
import { HandleGlobalizationService } from './plugins/handle.globalization.service';
import { RestInterceptorService } from './services/rest-interceptor.service';

export const setTranslateLoader = (http: HttpClient) =>
  new TranslateHttpLoader(http, './assets/i18n/', '.json');

const providers = [
  CookieService,
  { provide: AppVersion, useClass: HandleAppVersionService },
  { provide: FirebaseAnalytics, useClass: HandleFirebaseAnalyticsService },
  { provide: FirebaseX, useClass: HandleFirebaseService },
  { provide: Globalization, useClass: HandleGlobalizationService },
  TranslateService,
  { provide: HTTP_INTERCEPTORS, useClass: RestInterceptorService, multi: true },
  ConfirmationService,
  MessageService,
];

@NgModule({
  imports: [],
  providers: [...providers],
  exports: [],
})
export class CoreModule {}
