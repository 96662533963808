import { Component, OnInit, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { BaseFiltersComponent } from 'src/app/core/base/base-filters.component';
import { P360DropdownItem } from 'src/app/models/common';
import { AnalyticsCubesRapidFilter } from 'src/app/store/filters/store.filters';
import { appStoreFiltersActions } from 'src/app/store/filters/store.filters.action';
import { AppStorageService } from 'src/app/core/utils/app.storage.service';

@Component({
  selector: '[app-filters-analytics-cubes-sellin]',
  templateUrl: './app.filters.analytics-cubes.sellin.component.html',
})
export class AppFiltersAnalyticsCubesSellinComponent
  extends BaseFiltersComponent
  implements OnInit
{
  salesforceOptions: P360DropdownItem<AnalyticsCubesRapidFilter>[] = [];
  salesforceSelected?: number;
  supervisorOptions: P360DropdownItem<AnalyticsCubesRapidFilter>[] = [];
  supervisorSelected?: number;

  constructor(
    override store: Store<any>,
    override storage: AppStorageService,
    override renderer: Renderer2,
    override translate: TranslateService
  ) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.store
      .select('filters', 'analyticsCubes', 'salesforceSellinFilterList')
      .subscribe((salesforceSellinFilterList) => {
        this.salesforceOptions = salesforceSellinFilterList;
      });

    this.store
      .select('filters', 'analyticsCubes', 'salesforceSellinFilterOption')
      .subscribe((salesforceSellinFilterOption) => {
        this.salesforceSelected = salesforceSellinFilterOption.id;
      });

    this.store
      .select('filters', 'analyticsCubes', 'supervisorSellinFilterList')
      .subscribe((supervisorSellinFilterList) => {
        this.supervisorOptions = supervisorSellinFilterList;
      });

    this.store
      .select('filters', 'analyticsCubes', 'supervisorSellinFilterOption')
      .subscribe((supervisorSellinFilterOption) => {
        this.supervisorSelected = supervisorSellinFilterOption.id;
      });
  }

  onSellinCubeFilterChange = ({ value }: DropdownChangeEvent) => {
    const options = this.isSalesforce
      ? this.salesforceOptions
      : this.supervisorOptions;
    const found = options.find((item) => item.id === value);

    const name = this.isSalesforce
      ? 'salesforceSellinFilterOption'
      : 'supervisorSellinFilterOption';
    this.store.dispatch(
      appStoreFiltersActions.setAnalyticsCubesSellinSelectedAction({
        name,
        option: found,
      })
    );
  };

  override customClearFilters = () => {
    const name = this.isSalesforce
      ? 'salesforceSellinFilter'
      : 'supervisorSellinFilter';
    this.store.dispatch(
      appStoreFiltersActions.clearAnalyticsCubesSellinFilterAction({
        name,
      })
    );
  };
}
