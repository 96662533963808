import { AfterViewInit, Component, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: '[app-headline]',
  template: `<ng-content></ng-content>`,
})
export class AppHeadlineComponent implements AfterViewInit {
  headlineSize = 0;

  constructor(public el: ElementRef, protected renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.renderer.addClass(this.el.nativeElement, 'font-normal');
    this.renderer.addClass(this.el.nativeElement, 'text-base');
    this.renderer.addClass(this.el.nativeElement, 'text-center');
    this.renderer.addClass(this.el.nativeElement, 'm-0');

    this.headlineSize = this.el?.nativeElement?.scrollHeight ?? 0;
  }
}
