import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
} from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: '[p360-picture-selector]',
  template: `
    <div class="relative" *ngIf="pictureData != null">
      <img
        class="w-full h-auto"
        [src]="pictureData"
        alt="appointment preview"
      />
      <div class="absolute" [style.top.px]="-10" [style.right.px]="-10">
        <p-button
          type="button"
          icon="fa-solid fa-trash-alt"
          styleClass="bg-white"
          severity="danger"
          [outlined]="true"
          [rounded]="true"
          (onClick)="removePhoto()"
        ></p-button>
      </div>
    </div>

    <p-button
      type="button"
      icon="fa-solid fa-camera text-5xl"
      styleClass="w-full h-full"
      severity="success"
      [outlined]="true"
      (onClick)="addPhoto()"
      *ngIf="pictureData == null"
      [style.width.px]="100"
      [style.height.px]="100"
    ></p-button>
  `,
})
export class AppPictureSelectorComponent implements AfterViewInit {
  @Input({ required: true }) agentCode: string = '';

  @Input({ required: true }) pictureData?: string;
  @Output() pictureDataChange = new EventEmitter<string>();

  @Input() pictureName?: string;
  @Output() pictureNameChange = new EventEmitter<string>();

  constructor(
    protected el: ElementRef,
    protected renderer: Renderer2,
    protected service: SharedService
  ) {}

  ngAfterViewInit(): void {
    this.renderer.addClass(this.el.nativeElement, 'inline-flex');
    this.renderer.addClass(this.el.nativeElement, 'align-items-center');
    this.renderer.addClass(this.el.nativeElement, 'justify-content-center');
  }

  removePhoto = () => {
    this.pictureDataChange.emit(null);
    this.pictureNameChange.emit(null);
  };

  addPhoto = () => {
    this.service.requestCameraPermissions(() => {
      this.service
        .getPhoto(this.agentCode)
        .then((data) => {
          this.pictureDataChange.emit(data.photoPath);
          this.pictureNameChange.emit(data.photoName);
        })
        .catch(this.service.failOnGetFile);
    });
  };
}
