<div class="w-full">
  <p-divider align="center" layout="horizontal">
    <span class="p-tag">&amp;</span>
  </p-divider>
</div>

<div class="flex justify-content-center">
  <div class="col-12 md:col-4 flex flex-column">
    <label class="font-bold" for="customerFilter">{{ "CUSTOMER" | translate }}</label>

    <div class="w-full">
      <p-dropdown
        validation
        #customerFilter
        [(ngModel)]="clientSelected"
        (onChange)="onClientChange($event)"
        name="customerFilter"
        [options]="clientOptions"
        optionLabel="label"
        optionValue="id"
        styleClass="w-full"
        appendTo="body"
      >
        <ng-template let-item pTemplate="selectedItem">
          <span [translate]="item.label"></span>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <span [translate]="item.label"></span>
        </ng-template>
      </p-dropdown>
    </div>
  </div>
</div>

<div [ngClass]="{ 'col-12 p-0': isSmall }" *ngIf="isSmall">
  <p-divider align="center" layout="horizontal">
    <span class="p-tag" *ngIf="isSmall">&amp;</span>
  </p-divider>
</div>

<div class="flex justify-content-center">
  <div class="col-12 md:col-4 text-center">
    <p-selectButton validation name="dateTypeSelection" [(ngModel)]="dateTypeSelected" [options]="dateTypeOptions" (onChange)="onDateTypeChange($event)">
      <ng-template let-item pTemplate>
        <span [translate]="item.label"></span>
      </ng-template>
    </p-selectButton>
  </div>
</div>

<div class="text-right mt-2">
  <p-button icon="pi pi-eraser text-2xl" type="button" [outlined]="true" [rounded]="true" severity="danger" [text]="true" (onClick)="clearFilters()"></p-button>
</div>
