import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-customer-icon',
  template: `
    <img
      alt="customer {{ icon }}"
      src="/assets/imgs/crm/{{ icon }}.png"
      [height]="size"
      [width]="size"
    />
  `,
})
export class AppCustomerIconComponent {
  @Input() type = '';
  @Input() size = 50;

  get icon() {
    if (this.type === 'M') {
      return 'medic';
    } else if (this.type === 'F') {
      return 'drugstore';
    } else {
      return 'client';
    }
  }
}
