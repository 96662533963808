import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@awesome-cordova-plugins/firebase-analytics/ngx';
import { Platform } from '@ionic/angular';

@Injectable()
export class HandleFirebaseAnalyticsService extends FirebaseAnalytics {
  constructor(private platform: Platform) {
    super();
  }

  override setCurrentScreen(name: string): Promise<any> {
    if (this.platform.is('android') || this.platform.is('ios')) {
      return super.setCurrentScreen(name);
    }
    return new Promise((resolve, _) => {
      resolve('Mock firebase analytics');
    });
  }

  override logEvent(name: string, params: any): Promise<any> {
    if (this.platform.is('android') || this.platform.is('ios')) {
      return super.logEvent(name, params);
    }
    return new Promise((resolve, _) => {
      resolve('Mock firebase analytics');
    });
  }

  override setUserProperty(name: string, value: string): Promise<any> {
    if (this.platform.is('android') || this.platform.is('ios')) {
      return super.setUserProperty(name, value);
    }
    return new Promise((resolve, _) => {
      resolve('Mock firebase analytics');
    });
  }
}
