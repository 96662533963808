import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { AppService } from 'src/app/app.service';
import { BaseComponent } from 'src/app/core/base/base.component';
import { AppMessageService } from 'src/app/core/services/app-message.service';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { MenuService } from 'src/app/layout/app.menu.service';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import {
  AppSettings,
  AppSettingsFormat,
  AppSettingsLanguage,
} from 'src/app/store/settings/store.settings';
import { appStoreSettingsActions } from 'src/app/store/settings/store.settings.action';
import { AppStorage } from 'src/app/core/utils/app.storage';
import { AppStorageService } from 'src/app/core/utils/app.storage.service';

@Component({
  selector: 'app-config',
  templateUrl: './app.config.component.html',
})
export class AppConfigComponent extends BaseComponent implements OnInit {
  @Input() minimal: boolean = false;

  componentThemes: any[] = [];

  scales: number[] = [12, 13, 14, 15, 16];

  language: AppSettingsLanguage = 'es';
  format: AppSettingsFormat = 'units';
  precision: number = 0;

  agentCode: string = '';

  constructor(
    public layoutService: LayoutService,
    public menuService: MenuService,
    override store: Store<any>,
    protected cookie: CookieService,
    protected appStorageService: AppStorageService,
    protected appService: AppService,
    override platform: Platform,
    protected messageService: AppMessageService,
    protected firebaseService: FirebaseService
  ) {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();

    this.componentThemes = [
      { name: 'p360', lightColor: '#FF5722', darkColor: '#FF5722' },
      { name: 'orange', lightColor: '#DE911D', darkColor: '#E8AB4F' },
      { name: 'indigo', lightColor: '#4C63B6', darkColor: '#6A7EC2' },
      { name: 'blue', lightColor: '#1992D4', darkColor: '#3BABE8' },
      { name: 'green', lightColor: '#27AB83', darkColor: '#44D4A9' },
      { name: 'deeppurple', lightColor: '#896FF4', darkColor: '#B1A0F8' },
      { name: 'cyan', lightColor: '#00B9C6', darkColor: '#58CDD5' },
      { name: 'yellow', lightColor: '#F9C404', darkColor: '#FDDD68' },
      { name: 'pink', lightColor: '#C74B95', darkColor: '#D77FB4' },
      { name: 'purple', lightColor: '#BA6FF4', darkColor: '#D1A0F8' },
      { name: 'lime', lightColor: '#84BD20', darkColor: '#A3D44E' },
    ];

    this.store.select('settings').subscribe((settings: AppSettings) => {
      this.language = settings.language;
      this.format = settings.format;
      this.precision = settings.precision;
    });

    this.store
      .select('global', 'collaborator', 'agentCode')
      .subscribe((agentCode: string) => {
        this.agentCode = agentCode;
      });

    if (this.isSmall) {
      this.decrementScale();
    }
  }

  changeTheme(theme: string) {
    this.currentTheme = theme;
  }

  isIE() {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
  }

  onConfigButtonClick() {
    this.layoutService.showConfigSidebar();
  }

  decrementScale() {
    this.scale--;
  }

  incrementScale() {
    this.scale++;
  }

  handleFormatChange = (_: any) => {
    this.isLoading = true;
    setTimeout(() => {
      this.appStorageService.set(AppStorage.SETTINGS_FORMAT, this.format);
      this.store.dispatch(
        appStoreSettingsActions.setFormatAction({
          format: this.format,
        })
      );
    }, 50);
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  };

  handlePrecisionChange = (_: any) => {
    this.isLoading = true;
    setTimeout(() => {
      this.appStorageService.set(AppStorage.SETTINGS_PRECISION, this.precision);
      this.store.dispatch(
        appStoreSettingsActions.setPrecisionAction({
          precision: this.precision,
        })
      );
    }, 50);
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  };

  handleRefreshInfo = (_: MouseEvent) => {
    this.appService.handleRefreshInformation(this.agentCode, true);
  };

  handleClearAll = (_: MouseEvent) => {
    if (this.user) {
      this.firebaseService.unsubscribe(this.user);
    }
    this.layoutService.cleanRestCache();
    this.appStorageService.clear();
    window.location.reload();
  };

  get currentTheme(): string {
    return this.layoutService.config().theme;
  }
  set currentTheme(_val: string) {
    this.layoutService.config.update((config) => ({
      ...config,
      theme: _val,
    }));
  }

  get visible(): boolean {
    return this.layoutService.state.configSidebarVisible;
  }
  set visible(_val: boolean) {
    this.layoutService.state.configSidebarVisible = _val;
  }

  get scale(): number {
    return this.layoutService.config().scale;
  }
  set scale(_val: number) {
    this.isLoading = true;
    setTimeout(() => {
      this.store.dispatch(
        appStoreSettingsActions.setScaleAction({
          scale: _val,
        })
      );
      this.layoutService.config.update((config) => ({
        ...config,
        scale: _val,
      }));
    }, 50);
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  }

  get menuVisible(): boolean {
    return this.layoutService.state.staticMenuMobileActive;
  }

  get isRefreshing(): boolean {
    return this.appService.loadInfoCounter;
  }
}
