<div class="flex gap-4" [ngClass]="{ 'flex-column-reverse': isSmall, 'flex-column': !isSmall }">
  <h3 class="w-full font-normal text-base text-center m-0">
    <span class="font-bold" [innerHtml]="'PLAN_WITH_ICON' | translate : { icon: formatIcon }"></span>:
    <span>{{ formattedPlan }}</span>
    /
    <span class="font-bold" [innerHtml]="'SALES_WITH_ICON' | translate : { icon: formatIcon }"></span>:
    <span>{{ formattedSales }}</span>
  </h3>

  <div class="relative">
    <dx-circular-gauge [value]="value">
      <dxo-range-container [offset]="0" [width]="barWidth">
        <dxi-range [color]="pharmaColors.lowFulfillment" [startValue]="0" [endValue]=".8"></dxi-range>
        <dxi-range [color]="pharmaColors.normalFulfillment" [startValue]=".8" [endValue]="1"></dxi-range>
        <dxi-range [color]="pharmaColors.highFulfillment" [startValue]="1" [endValue]="10"></dxi-range>
      </dxo-range-container>

      <dxo-scale [startValue]="0" [endValue]="endValue" [tickInterval]=".2">
        <dxo-label [indentFromTick]="10">
          <dxo-format type="percent" precision="0"></dxo-format>
          <dxo-font size="1rem"></dxo-font>
        </dxo-label>
        <dxo-tick color="#536878" [length]="10"></dxo-tick>
      </dxo-scale>

      <dxo-size [width]="width" [height]="height"></dxo-size>

      <dxo-value-indicator type="textCloud" color="#E6E6E6" [offset]="-15">
        <dxo-text>
          <dxo-format type="percent" precision="2"></dxo-format>
          <dxo-font color="#000000" size="1rem"></dxo-font>
        </dxo-text>
      </dxo-value-indicator>
    </dx-circular-gauge>

    <ng-content></ng-content>
  </div>
</div>
